import React, { Children, useState, ReactElement, useEffect } from "react";

import classNames from "classnames";

import GridGame from "components/Games/GridGame";
import NumberGame from "components/Games/NumberGame";
import Game from "components/Games/Game";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";

// import Header from "./Header";
// import InputTodo from "./InputTodo";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

// Icons for tiles
import CachedIcon from "@material-ui/icons/Cached";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import TimerIcon from "@material-ui/icons/Timer";
import HearingIcon from "@material-ui/icons/Hearing";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IGameProps } from "components/Games/IGame";
import { IGameRouteProps } from "./IGameRoute";
import GameRoute from "./GamePage";
import GamesTable from "./GamesTable";
import { PuzzleTypes } from "components/Settings/PuzzleSettings";

import ImageBadge from "components/Badges/ImageBadge";
// import tileData from './tileData';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory
} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
      marginTop: 4,
    },
    gridList: {
      width: "xs",
      height: "xs",
      margin: "10px !important"
    },
    tileContainer: {
      position: "relative"
    },
    clearTileBar: {
      background: 'rgba(0, 0, 0, 0)'
    },
    tileBar: {
      background: theme.palette.primary.main + "f0"
    },
    tileBar1: {
      background: "#1E7697f0"
    },
    tileBar2: {
      background: "#1E3A97f0"
    },
    tileBar3: {
      background: "#1E977Bf0"
    },
    tileBar4: {
      background: "#FF715Bf0"
    },
    tileBar5: {
      background: "#A64253f0"
    },
    tileInside: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "50%"
    },
    tile: {
      paddingTop: 50
    },
    gridListTile: {
      // border: "4px solid",
      "&:hover": {
        border: "2px solid " + theme.palette.primary.main
      },
      "& .MuiGridListTile-tile": {
        border: "2px solid" + theme.palette.primary.main
      }
      // margin: 4
    },
    gridListTileComplete: {
      // border: "2px solid " + theme.palette.secondary.main,
      "& .MuiGridListTile-tile": {
        border: "2px solid " + theme.palette.secondary.main,
        background:
          "repeating-linear-gradient(45deg,  rgba(30, 151, 123, 0.2),rgba(30, 151, 123, 0.2) 10px, rgba(30, 151, 123, 0.3) 10px, rgba(30, 151, 123, 0.3) 20px)"
      }
      // margin: 4
      // backgroundColor: "green"
    },
    gridListTileCompleteWithHint: {
      // border: "4px solid red",
      "& .MuiGridListTile-tile": {
        border: "2px solid " + theme.palette.secondary.main,
        // background:
        //   "repeating-linear-gradient(45deg,  rgba(195, 34, 40, 0.2),rgba(195, 34, 40, 0.2) 10px, rgba(195, 34, 40, 0.3) 10px, rgba(195, 34, 40, 0.3) 20px)"
        background:
          "repeating-linear-gradient(45deg,  rgba(30, 151, 123, 0.2),rgba(30, 151, 123, 0.2) 10px, rgba(30, 151, 123, 0.3) 10px, rgba(30, 151, 123, 0.3) 20px)"
      }
      // margin: 4
      // backgroundColor: "red"
    },
    gridListTileFailed: {
      "& .MuiGridListTile-tile": {
        border: "2px solid rgba(195, 34, 40)",
        background:
          "repeating-linear-gradient(45deg,  rgba(195, 34, 40, 0.2),rgba(195, 34, 40, 0.2) 10px, rgba(195, 34, 40, 0.3) 10px, rgba(195, 34, 40, 0.3) 20px)"
      }
    },
    numberCircle: {
      width: 120,
      lineHeight: "120px",
      borderRadius: "50%",
      textAlign: "center",
      fontSize: 32,
      border: "2px solid #666",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: "50%"
    },
    pointsIcon: {
      // color: "rgba(255, 255, 255, 1)"
      color: theme.palette.success.main + 'cc'
    },
    icon: {
      color: "rgba(255, 255, 255, 1)"
    },
    iconList: {
      "& .svg": {
        color: "rgba(255, 255, 255, 1)",
        fontSize: "small"
      }
    },
    smallIcon: {
      color: "rgba(255, 255, 255, 1)",
      fontSize: "small"
    },
    mainIcon: {
      // fontSize: 10em,
    }
  })
);

type Props = {
  children: React.ReactElement<IGameProps>[] | React.ReactElement<IGameProps>;
};

const GameRoutes =({ children }: Props) => {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path="/games">
          <Games games={children} />
        </Route>
      </Switch>
    </div>
  );
}

const GameCards = ({ children }: Props) => {
  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  const [numberOfGames, setNumberOfGames] = useState(
    Children.count(children) - 1
  );

  let match = useRouteMatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsLoaded(true);
    console.log("It is now loaded");
  }, []);

  const newChildren = children;

  const hasFailed = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    if (element.props.progress?.is_complete == true) return false;

    if (
      element.props.defaultPuzzleSettings?.allowed_number_of_tries &&
      element.props.progress?.number_of_attempts
    ) {
      if (
        element.props.progress?.number_of_attempts >=
        element.props.defaultPuzzleSettings?.allowed_number_of_tries
      ) {
        return true;
      }
    }
  };

  const displayTitle = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    // console.log(element.props);

    if (element.props.title) return element.props.title;

    return "";
  };

  const displayPoints = (
    element: React.ReactElement<IGameProps>,
    noPoints?: boolean
  ) => {
    if (!React.isValidElement(element)) return;

    // console.log(element.props);

    if (element.props.points) {
      if (noPoints) {
        return element.props.points;
      }
      return element.props.points + " points";
    }

    return "0 Points";
  };

  const getImageUrl = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    // console.log(element.props);

    if (element.props.imageUrl) return element.props.imageUrl;

    return "Nothing";
  };

  const getIsComplete = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    // console.log("Is Complete: ", element.props.isComplete);

    if (element.props.isComplete == true) {
      // console.log("It is complete");
      return true;
    }

    // console.log("It is not complete");
    return false;
  };

  const getSize = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    if (element.props.points) {
      return element.props.points / 3;
    }

    return 1;
  };

  const isActive = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    // console.log("Is Active: ", element.props.isActive);

    if (element.props.isActive) {
      // console.log("It is complete");
      return element.props.isActive;
    }

    return 1;
  };

  const getUniqueId = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    // console.log("Is Active: ", element.props.isActive);

    if (element.props.unique_id) {
      // console.log("It is complete");
      return element.props.unique_id;
    }

    return "NA";
  };

  const getGridListTileClassName = (
    element: React.ReactElement<IGameProps>
  ) => {
    if (!React.isValidElement(element)) return;

    // console.log("Is Active: ", element.props.isActive);

    if (element.props.isComplete) {
      // console.log("It is complete");
      if (element.props.hintTaken) return classes.gridListTileCompleteWithHint;

      return classes.gridListTileComplete;
    }

    if (hasFailed(element)) {
      return classes.gridListTileFailed;
    }

    return classes.gridListTile;
  };

  const getIcons = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;
    let iconList = [];

    if (element.props.defaultPuzzleSettings?.is_timed == true) {
      iconList.push(<TimerIcon className={classes.smallIcon} />);
    }

    if (element.props.defaultPuzzleSettings?.can_change_answer == true) {
      iconList.push(<CachedIcon className={classes.smallIcon} />);
    }

    if (
      element.props.defaultPuzzleSettings?.allowed_number_of_tries &&
      element.props.defaultPuzzleSettings?.allowed_number_of_tries > 0
    ) {
      iconList.push(
        <FavoriteBorderOutlinedIcon className={classes.smallIcon} />
      );
    }

    if (element.props.is_parent) {
      iconList.push(<ExtensionOutlinedIcon className={classes.smallIcon} />);
    }

    if (element.props.audio) {
      iconList.push(<HearingIcon className={classes.smallIcon} />);
    }

    return (
      <div>
        <React.Fragment>{iconList}</React.Fragment>
      </div>
    );
  };

  const getCardClass = (element: React.ReactElement<IGameProps>) => {
    if (!React.isValidElement(element)) return;

    // console.debug("Game Type: ", element.props.gameType);

    if (element.props.is_custom) {
      return classes.tileBar5;
    }

    if (
      element.props.gameType == PuzzleTypes.WordGame ||
      element.props.gameType == PuzzleTypes.LimitedWordGame
    ) {
      return classes.tileBar1;
    }

    if (element.props.gameType == PuzzleTypes.NumberGame) {
      return classes.tileBar2;
    }

    if (
      element.props.gameType == PuzzleTypes.GridGame ||
      element.props.gameType == PuzzleTypes.GridSelectGame
    ) {
      return classes.tileBar3;
    }

    if (element.props.gameType == PuzzleTypes.MultipleChoiceGame) {
      return classes.tileBar4;
    }

    return classes.tileBar5;
  };

  const ChildrenToRows = () => {
    return React.Children.map(
      children,
      (child: React.ReactElement<IGameProps>, index: number) => ({
        id: index,
        name: displayTitle(child),
        points: displayPoints(child, true)
      })
    );
  };

  let history = useHistory();

  return (
    <div>
      {isLoaded == false ? <div><CircularProgress /></div> : ""}
      <div className={classes.root}>
        <GridList
          id="games-grid-list"
          cellHeight={200}
          spacing={20}
          className={classes.gridList}
          cols={window.innerWidth < 640 ? 1 : 3}
        >
          {React.Children.map(
            children,
            (child: React.ReactElement<IGameProps>, index: number) => (
              <GridListTile
                cols={1}
                className={getGridListTileClassName(child)}
                onClick={() => {
                  history.push(`/games/${index}`);
                }}
              >
                <img src={getImageUrl(child)} />
                <GridListTileBar
                  title={displayTitle(child)}
                  subtitle={
                    <div>
                      {displayPoints(child)} {getIcons(child)}
                    </div>
                  }
                  className={getCardClass(child)}
                />

                <GridListTileBar
                  className={classes.clearTileBar}
                  titlePosition="top"
                  actionIcon={
                    <IconButton className={classes.icon}>
                      <span className="fa-layers fa-fw">
                        <i className={classes.pointsIcon + " fas fa-certificate fa-1x"} data-fa-transform="grow-8"></i>
                        <span className="fa-layers-text fa-inverse" data-fa-transform="shrink-3">{displayPoints(child, true)}</span>
                      </span>
                    </IconButton>
                  }
                />
              </GridListTile>
            )
          )}
        </GridList>
      </div>
    </div>
  );
}

function Games({ games }: IGameRouteProps) {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:gameId`}>
          <GameRoute games={games} />
        </Route>
        <Route path={match.path}>
          <GameCards>{games}</GameCards>
        </Route>
      </Switch>
    </div>
  );
}

export default GameRoutes;
