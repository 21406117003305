import React, { useState, useEffect } from "react";
// import TodosList from "./TodosList";
// import Header from "./Header";
// import InputTodo from "./InputTodo";

import "./GridCell.css";
import axios from "axios";
import uuid from "uuid";

type Prop = {
  answerList: number[];
  currentNumber: Function;
  listHandler: Function;
  id: number;
  useNumber: boolean;
};

const GridCell = (props: Prop) => {
  const [number, setNumber] = useState(0);
  const [answerList, setAnswerList] = useState(props.answerList);
  const [useNumber, setUseNumber] = useState(props.useNumber);

  useEffect(() => {
    console.log("Use Effect is being called for ", number);
    console.log("Use number: ", useNumber);
    setNumber(props.answerList[props.id - 1]);
  }, [props.answerList]);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    console.debug("The Mouse is being clicked on id: ", props.id);
    var number_to_use = number;
    if (useNumber == false) {
      number_to_use = (number==0) ? 1: 0;
      props.listHandler.call(undefined, props.id, number_to_use);
    } else {
      if (number === 0) {
        number_to_use = props.currentNumber();
        setNumber(number_to_use);
        props.listHandler.call(undefined, props.id, number_to_use);
      }
    }
  };

  const displayCell = () => {
    if (useNumber === true) {
      return number === 0 ? " " : number;
    } else {
      return "";
    }
  };

  return (
    <td
      className={number === 0 ? "grid-cell" : "grid-cell highlighted"}
      id={props.id.toString()}
      onClick={onClick}
    >
      {displayCell()}
    </td>
  );
};

export default GridCell;
