import React, { Component } from "react";
import { Route, useHistory, Redirect } from "react-router-dom";

export const getAccessToken = () => localStorage.getItem("access_token");
export const getRefreshToken = () => localStorage.getItem("access_token");
export const isAuthenticated = () => !!getAccessToken();

export const AuthenticatedRoute = (
  component: Component,
  exact: boolean,
  path: string
) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <AuthenticateBeforeRender />
        )
      }
    />
  );
};

interface Props {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children, ...rest }: Props) => {
  console.debug("This is a private route - Authenticated: ", isAuthenticated());
  console.debug("children: ", children);

  return ("");
};

class AuthenticateBeforeRender extends Component {
  state = {
    isAuthenticated: false
  };

  componentDidMount() {}

  render() {
    return <Redirect to="/login" />;
    // return this.state.isAuthenticated ? this.props.render() : null
  }
}
