import React, { useState, useEffect } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";

// Folder Imports
import { IEventProgress } from "./IEventProgress";
import WelcomeCard from "./Welcome";

import AlertDialog from "components/AlertDialog";
import PageBreadcrumbs from "components/Breadcrumbs/PageBreadcrumbs";
import { useLocalStorage } from "components/Utils/Storage";
import { axiosInstance } from "api/axiosApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1)
      }
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    input: {
      display: "none"
    }
  })
);

export function DisplayEvent({ events }: { events: IEventProgress[] }) {
  const classes = useStyles();
  let { eventProgressId } = useParams();
  const history = useHistory();
  console.debug("Events: ", events);
  const [event, setEvent] = useState(() => {
    if (events.length != 0) {
      console.log("Events Length is ", events.length);
      console.log("Events: ", events);
      console.log("Event Progress ID: ", eventProgressId);
      for (var i = 0; i < events.length; i++) {
        if (events[i].id == eventProgressId) {
          return events[i] as IEventProgress;
        }
      }
      return undefined;
    }
  });
  const [hasStarted, setHasStarted] = useState(() => {
    if (event) {
      return event.has_started;
    }
  });
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleAccept = () => {
    axiosInstance
      .post("event/start", {
        event_progress_id: eventProgressId
      })
      .then(result => {
        console.debug("Received response from post: ", result);
        if (result.data.active === true) {
          handleClose();
          console.debug("History");
          // localStorage.setItem("current_event_id", JSON.stringify(eventId));
          localStorage.setItem("current_event_id", event?.event.id + "");
          localStorage.setItem(
            "event_end_time",
            JSON.stringify(result.data.end_time)
          );
          history.push("/games");
        }
      })
      .catch(err => {
        // Add message about unable to start event
        console.debug("There was an error");
        console.debug(err);
        return false;
      });
  };

  const countdownTimer = () => {
    // console.debug("Displaying Timer because we have the event progress");
    if (event?.event.start_date_time) {
      return (
        <div>
          <Typography variant="h6" align="center">
            This event is not available for another{" "}
          </Typography>
          <Countdown
            date={event.event.start_date_time}
            key="countdown"
            onTick={time => {}}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              return (
                <Typography variant="h6" align="center">
                  {days > 0 ? zeroPad(days) + " day(s)" : ""}{" "}
                  {hours > 0 ? zeroPad(hours) + " hour(s)" : ""}{" "}
                  {minutes > 0 ? zeroPad(minutes) + " minute(s)" : ""}{" "}
                  {seconds > 0 ? zeroPad(seconds) + " second(s)" : ""}
                </Typography>
              );
            }}
          />
        </div>
      );
    }
    return "";
  };

  const StartEventButton = () => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        {event?.event.is_active ? (
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Start Event
          </Button>
        ) : (
          countdownTimer()
        )}
      </div>
    );
  };

  const eventHasStarted = () => {
    if (hasStarted) {
      console.debug(
        "The Event for ID: ",
        event?.event.id,
        " has already started"
      );
      localStorage.setItem("current_event_id", event?.event.id + "");
      return <Redirect to="/games" />;
    } else {
      return <div>{StartEventButton()}</div>;
    }
  };

  const displayEvent = () => {
    if (event) {
      return (
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <div className={classes.paper}>
            <WelcomeCard
              imageUrl={event.event.image?.upload_url}
              name="Sage Publishing"
            >
              <Typography variant="h6" paragraph align="center" color="error">
                <strong>
                  Important - read the{" "}
                  {<Link to="/instructions">instructions</Link>} before clicking
                  "Start Event"
                </strong>
              </Typography>
              <div>{eventHasStarted()}</div>
            </WelcomeCard>
          </div>
        </Container>
      );
    }
  };

  if (events.length != 0 && event) {
    console.debug("Event: ", event);
    return (
      <div>
        <PageBreadcrumbs
          mainPage={{ name: "Events", path: "/events" }}
          currentPage={{ name: event.event_name, path: "/" }}
        />
        {displayEvent()}

        <AlertDialog
          visible={showModal}
          handleClose={handleClose}
          handleAccept={handleAccept}
          title="Are you ready to start?"
          cancelText="Not Yet"
          acceptText="Let's Start!"
        >
          <Typography gutterBottom>
            Before you click <b>"Let's Start"</b> make sure that your entire
            team is here.
          </Typography>
          <Typography gutterBottom>
            Once you click "Let's Start" your time will begin. Good Luck!
          </Typography>{" "}
        </AlertDialog>
      </div>
    );
  } else return <Redirect to="/events" />;
}
