import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "md",
    },
    cardHeader: {
      "& .MuiCardHeader-content span": {
        fontSize: '3em',
        fontWeight: 'bold',
      }
    },
    media: {
      maxHeight: 300,
      objectFit: 'scale-down',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }),
);

interface Props {
  name: string;
  imageUrl?: string;
  children?: React.ReactNode;
}

export default function WelcomeCard(props: Props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Welcome,"
        className={classes.cardHeader}
      />
      {props.imageUrl ?
      (<CardMedia
        className={classes.media}
        image={props.imageUrl}
        component="img"
      /> ): ""}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.children}
        </Typography>
      </CardContent>
    </Card>
  );
}
