import React, { Children, useState, ReactElement, useEffect } from "react";
import GameContainer from "../GameContainer";
import GameRoutes from "components/GamesPage/GameRoutes";
import GridGame from "components/Games/GridGame";
import NumberGame from "components/Games/NumberGame";
import LetterGame from "components/Games/LetterGame";
import MultipleChoiceGame from "components/Games/MultipleChoiceGame";
import DragAndDropGame from "components/Games/DragAndDropGame";
import PointTracker from "components/Widgets/PointTracker";
import MessageModal from "components/Modal";
import MessageDialog from "components/Dialog";
import AlertDialog from "components/AlertDialog";
import AppBar from "components/Navigation/AppBar";
import MiniDrawer from "components/Navigation/MiniDrawer";
import TemporaryDrawer from "components/Navigation/TemporaryDrawer";
import ClippedDrawer from "components/Navigation/ClippedDrawer";
import { useLocalStorage, useMapState } from "components/Utils/Storage";
import MainLayout from "components/Layouts/MainLayout";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Header from "../Header";
import NavBar from "../NavBar";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";

import { gameData } from "../GameData";
import { PuzzleTypes } from "components/Settings/PuzzleSettings";
import { axiosInstance } from "api/axiosApi";
import { useInterval } from "./Hooks/UseInterval";
import WindowFocusHandler from "./Hooks/WindowFocusHandler";

import axios from "axios";
import uuid from "uuid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    marginAutoItem: {
      margin: "auto"
    },
    marginAutoContainer: {
      display: "flex"
    },
    grid: {
      // width: '100%'
    }
  })
);

interface Props {}

interface IPuzzleProgress {
  id: number;
  puzzle: number;
  is_complete: boolean;
  hint_taken: boolean;
  number_of_attempts: number;
}

export default function BoardContainer({}: Props) {
  const classes = useStyles();
  const [pointTotal, setPointTotal] = useLocalStorage("point_total", 0);
  const [endTime, setEndTime] = useLocalStorage("event_end_time", "");
  const [isActive, setIsActive] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [haveEventProgress, setHaveEventProgress] = useState(false);
  const [haveAllRequests, setHaveAllRequests] = useState(false);
  // const endTime = localStorage.getItem('event_end_time') as string;
  const [eventProgressId, setEventProgressId] = useLocalStorage(
    "current_event_progress_id",
    1
  );
  const [eventId, setEventId] = useLocalStorage("current_event_id", -1);
  // const [puzzleStatesMap, setPuzzleStatesMap] = useMapState(
  //   "puzzleStatesMap",
  //   new Map<string, boolean>()
  // );
  const [puzzleHintsMap, setPuzzleHintsMap] = useMapState(
    "puzzleHintsMap",
    new Map<string, boolean>()
  );

  const [puzzleProgressesMap, setPuzzleProgressesMap] = useMapState(
    "puzzleProgressesMap",
    new Map<number, IPuzzleProgress>()
  );

  const [gameDataToUse, setGameDataToUse] = useState(gameData);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [modalTitle, setModalTitle] = useState("Contacting HQ...");
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [refreshNumber, setRefreshNumber] = useState(0);
  const [inFocus, setInFocus] = useState(true);

  const [
    showHintRequestConfirmationModal,
    setShowHintRequestConfirmationModal
  ] = useState(false);
  const [currentHintPuzzleId, setCurrentHintPuzzleId] = useState(0);

  const history = useHistory();

  // const updatePuzzleState = (puzzle_id: number, state: boolean) => {
  //   var tempMap = puzzleStatesMap.set("complete-" + puzzle_id, state);
  //   setPuzzleStatesMap(tempMap);
  // };

  const updatePuzzleProgressesState = (puzzle_id: number, state: boolean) => {
    var tempMap = puzzleProgressesMap.get(puzzle_id);
    console.debug("Temp Map for Puzzle Progresses:", tempMap);
    console.debug("Temp Map Is Complete:", tempMap.is_complete);
    tempMap.is_complete = true;
    console.debug("Temp Map for Puzzle Progresses after set: ", tempMap);
    tempMap = puzzleProgressesMap.set(puzzle_id, tempMap);
    setPuzzleProgressesMap(tempMap);
  };

  const puzzleAttempted = (puzzle_id: number) => {
    var progress = puzzleProgressesMap.get(puzzle_id);
    progress.number_of_attempts = progress.number_of_attempts + 1;

    setPuzzleProgressesMap(puzzleProgressesMap.set(puzzle_id, progress));
  };

  const submitAnswer = (puzzle_id: number, answer: string) => {
    var progress = puzzleProgressesMap.get(puzzle_id);
    progress.last_answer = answer;

    setPuzzleProgressesMap(puzzleProgressesMap.set(puzzle_id, progress));
  };

  const checkAnswer = (puzzle_id: number, answer: string) => {
    //Send message to server letting it know that the question is correct
    let data = {
      answer: answer
    };

    let isCorrect = false;

    console.log(
      "Is already correct: ",
      puzzleProgressesMap.get(puzzle_id).is_complete
    );

    if (
      puzzleProgressesMap.get(puzzle_id).is_complete == undefined ||
      puzzleProgressesMap.get(puzzle_id).is_complete == false
    ) {
      setWaitingForResponse(true);
      setModalTitle("Checking with HQ");
      setShowModal(true);
      axiosInstance
        // .post("event/" + eventProgressId + "/puzzle/" + puzzle_id + "/answer", data)
        .post("puzzle/answer", {
          event_progress_id: eventProgressId,
          puzzle_id: puzzle_id,
          answer: answer
        })
        .then(result => {
          if (result.data.submitted) {
            setModalMessage("Your answer has been successfully submitted");
            setModalTitle("Sucessful Transmission");
            submitAnswer(puzzle_id, answer);
            puzzleAttempted(puzzle_id);
            setWaitingForResponse(false);
          } else {
            isCorrect = result.data.success;
            // console.debug("Is Correct: ", isCorrect);
            setModalMessage(result.data.message);
            setModalTitle("Response Received");
            puzzleAttempted(puzzle_id);
            setIsSuccess(isCorrect);
            // setShowModal(true);
            setWaitingForResponse(false);
            if (isCorrect) {
              setPointTotal(result.data.point_total);
              updatePuzzleProgressesState(puzzle_id, true);
            }
            // console.log("Puzzle states: ", puzzleStatesMap);
            return isCorrect;
          }
          // }
        })
        .catch(err => {
          console.log("There was an error");
          console.log(err);
          return false;
        });
    } else {
      setModalMessage("You've already solved this");
      setShowModal(true);
      return false;
    }

    return isCorrect;
  };

  const confirmHintRequest = (puzzle_id: number) => {
    setCurrentHintPuzzleId(puzzle_id);
    setShowHintRequestConfirmationModal(true);
  };

  const requestHint = (puzzle_id: number) => {
    let hint = "";

    console.debug("Requesting hint for: ", puzzle_id);

    setWaitingForResponse(true);
    setModalTitle("Requesting Hint from HQ");
    setShowModal(true);
    axiosInstance
      // .get("event/" + eventProgressId + "/puzzle/" + puzzle_id + "/hint")
      .post("puzzle/hint", {
        event_progress_id: eventProgressId,
        puzzle_id: puzzle_id
      })
      .then(result => {
        console.debug("Received response from post: ", result);
        // setPointTotal(result.data.results);
        hint = result.data.hint;
        console.debug("Hint: ", hint);
        setModalMessage(hint);
        setModalTitle("Hint Received");
        setWaitingForResponse(false);
        setPuzzleHintsMap(puzzleHintsMap.set("hint-" + puzzle_id, true));
        console.debug("Puzzle Hints Map: ", puzzleHintsMap);
      })
      .catch(err => {
        console.debug("There was an error");
        console.debug(err);
        return false;
      });
  };

  const refreshList = async () => {
    axiosInstance
      .get("event/" + eventId + "/puzzles?format=json")
      .then(result => {
        console.log("Received data: ", result.data);
        console.log("Received data Length: ", result.data.length);
        setGameDataToUse(result.data);
        console.log("Game Data: ", gameDataToUse);
      })
      .catch(err => {
        console.log("There was an error");
        console.log(err);
      });
  };

  const getEventProgress = async () => {
    axiosInstance
      .get("eventprogresses/" + eventId + "/?format=json")
      .then(result => {
        console.debug("Received Event Progress Data: ", result.data);
        // setIsActive(!result.data.isActive);
        setEndTime(result.data[0].end_date_time);
        setPointTotal(result.data[0].total_points);
        setEventProgressId(result.data[0].id);
        setEventId(result.data[0].event.id);
        setHaveEventProgress(true);
      })
      .catch(err => {
        console.log("There was an error");
        console.log(err);
      });
  };

  const getPuzzleProgresses = async () => {
    axiosInstance
      .get("event/" + eventId + "/puzzleprogresses?format=json")
      .then(result => {
        console.debug("Received Puzzle Progress Data: ", result.data);

        result.data.map((progress: IPuzzleProgress) => {
          setPuzzleHintsMap(
            puzzleHintsMap.set("hint-" + progress.puzzle, progress.hint_taken)
          );
          setPuzzleProgressesMap(
            puzzleProgressesMap.set(progress.puzzle, progress)
          );
        });

        setRefreshNumber(refreshNumber + 1);
      })
      .catch(err => {
        console.log("There was an error");
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("Refresh the puzzle progress map");
  }, [refreshNumber]);

  useEffect(() => {
    getPuzzleProgresses();
  }, [pointTotal]);

  useEffect(() => {
    getPuzzleProgresses();
    refreshList();
    getEventProgress();
    console.debug("Use Effect Point Total: ", pointTotal);
    console.debug("Use Effect End Date Time: ", endTime);
  }, []);

  useInterval(async () => {
    console.log("Is in focus: ", inFocus);
    if(inFocus && isActive) {
      getEventProgress();
    }
  }, 60000);

  const dataToGames = () => {
    console.debug("Data to Games: ", gameDataToUse);
    let games: React.ReactElement[] = [];

    gameDataToUse.map((game: any) => {
      // console.log("Game: ", game);
      if (game.image) {
        // console.log("Image: ", game.image.upload_url);
        if (game.type == PuzzleTypes.NumberGame) {
          games.push(
            <NumberGame
              key={game.id}
              id={game.id}
              unique_id={game.unique_id}
              title={game.display_name}
              instructions={game.description}
              answer={game.answer}
              points={game.points}
              answer_length={game.answer_length}
              checkAnswer={checkAnswer}
              imageUrl={game.image.upload_url}
              questionImageUrl={
                game.question_image ? game.question_image.upload_url : ""
              }
              isComplete={puzzleProgressesMap.get(game.id).is_complete}
              gameType={game.type}
              requestHint={confirmHintRequest}
              hintTaken={puzzleHintsMap.get("hint-" + game.id)}
              progress={puzzleProgressesMap.get(game.id)}
              audio={game.audio}
              defaultPuzzleSettings={game.default_puzzle_settings}
              is_custom={game.is_custom}
              has_hint={game.has_hint}
            />
          );
        } else if (
          game.type == PuzzleTypes.GridGame ||
          game.type == PuzzleTypes.GridSelectGame
        ) {
          games.push(
            <GridGame
              key={game.id}
              id={game.id}
              unique_id={game.unique_id}
              title={game.display_name}
              instructions={game.description}
              answer={game.answer}
              points={game.points}
              answer_length={game.answer_length}
              checkAnswer={checkAnswer}
              imageUrl={game.image.upload_url}
              questionImageUrl={
                game.question_image ? game.question_image.upload_url : ""
              }
              isComplete={puzzleProgressesMap.get(game.id).is_complete}
              gameType={game.type}
              requestHint={confirmHintRequest}
              hintTaken={puzzleHintsMap.get("hint-" + game.id)}
              progress={puzzleProgressesMap.get(game.id)}
              defaultPuzzleSettings={game.default_puzzle_settings}
              is_parent={game.is_parent}
              is_custom={game.is_custom}
              has_hint={game.has_hint}
            />
          );
        } else if (
          game.type == PuzzleTypes.WordGame ||
          game.type == PuzzleTypes.LimitedWordGame
        ) {
          games.push(
            <LetterGame
              key={game.id}
              id={game.id}
              unique_id={game.unique_id}
              title={game.display_name}
              instructions={game.description}
              answer={game.answer}
              points={game.points}
              answer_length={game.answer_length}
              checkAnswer={checkAnswer}
              imageUrl={game.image.upload_url}
              questionImageUrl={
                game.question_image ? game.question_image.upload_url : ""
              }
              isComplete={puzzleProgressesMap.get(game.id).is_complete}
              gameType={game.type}
              requestHint={confirmHintRequest}
              hintTaken={puzzleHintsMap.get("hint-" + game.id)}
              progress={puzzleProgressesMap.get(game.id)}
              defaultPuzzleSettings={game.default_puzzle_settings}
              is_custom={game.is_custom}
              has_hint={game.has_hint}
            />
          );
        } else if (game.type == PuzzleTypes.MultipleChoiceGame) {
          games.push(
            <MultipleChoiceGame
              key={game.id}
              id={game.id}
              unique_id={game.unique_id}
              title={game.display_name}
              instructions={game.description}
              answer={game.answer}
              points={game.points}
              answer_length={game.answer_length}
              checkAnswer={checkAnswer}
              imageUrl={game.image.upload_url}
              questionImageUrl={
                game.question_image ? game.question_image.upload_url : ""
              }
              isComplete={puzzleProgressesMap.get(game.id).is_complete}
              gameType={game.type}
              requestHint={confirmHintRequest}
              hintTaken={puzzleHintsMap.get("hint-" + game.id)}
              answerOptions={game.answer_option_group.answer_options}
              video={game.video}
              progress={puzzleProgressesMap.get(game.id)}
              defaultPuzzleSettings={game.default_puzzle_settings}
              is_custom={game.is_custom}
              has_hint={game.has_hint}
            />
          );
        }
      }
    });

    // console.log("Games: ", games);
    return games;
  };

  const showCheckingModal = () => {};

  const handleClose = () => {
    setShowModal(false);
  };

  const handleAccept = () => {
    history.push("/games");
    setShowModal(false);
  };

  const handleHintRequestClose = () => {
    setShowHintRequestConfirmationModal(false);
  };

  const handleHintRequestAccept = () => {
    requestHint(currentHintPuzzleId);
    setShowHintRequestConfirmationModal(false);
  };

  // Only display once you've receive the event progress
  const countdownTimer = () => {
    if (haveEventProgress) {
      // console.debug("Displaying Timer because we have the event progress");
      return (
        <Countdown
          date={endTime}
          key="countdown"
          onStart={time => {
            console.debug("Timer upon start: ", time);
            setIsActive(!time.completed);
          }}
          onMount={calcTimeDelta => {
            setIsActive(!calcTimeDelta.completed);
          }}
          onComplete={() => {
            setIsActive(false);
          }}
          onTick={time => {}}
          renderer={({ hours, minutes, seconds, completed }) => {
            return (
              <span>
                <span className="countdown-hours">{zeroPad(hours)}</span>:<span className="countdown-hours">{zeroPad(minutes)}</span>:<span className="countdown-hours">{zeroPad(seconds)}</span>
              </span>
            );
          }}
        />
      );
    }
  };

  console.debug("Event ID: ", eventId);
  if (eventId != -1) {
    return (
      <div className={classes.root} onFocus={() => setInFocus(true)} onBlur={() => setInFocus(false)}>
        {/* <MiniDrawer points={pointTotal} countdown={countdownTimer()}> */}
        <TemporaryDrawer points={pointTotal} countdown={countdownTimer()}>
          <MainLayout>
            {!haveEventProgress ? (
              <div><CircularProgress /> Loading puzzles...</div>
            ) : isActive ? (
              <React.Fragment>
              <WindowFocusHandler onFocusF={() => setInFocus(true)} onBlurF={() => setInFocus(false)} />
              <Grid container className={classes.grid} spacing={3}>
                <Grid item xs={12}>
                  <GameRoutes>{dataToGames()}</GameRoutes>
                </Grid>
              </Grid>
            </React.Fragment>
            ) : (
              <div>No more time!</div>
            )}
          </MainLayout>
        </TemporaryDrawer>
        {/* </MiniDrawer> */}

        <AlertDialog
          visible={showHintRequestConfirmationModal}
          handleClose={handleHintRequestClose}
          handleAccept={handleHintRequestAccept}
          waitingForMessage={false}
          title="Hint request confirmation"
          cancelText="No"
          acceptText="Yes, request a hint!"
        >
          <Typography gutterBottom>Do you want to request a hint?</Typography>
        </AlertDialog>
        <AlertDialog
          visible={showModal}
          handleClose={handleClose}
          handleAccept={handleAccept}
          waitingForMessage={waitingForResponse}
          title={modalTitle}
          cancelText="Exit"
          acceptText="Go to all Games"
        >
          {waitingForResponse ? (
            <div className={classes.marginAutoContainer}>
              <div className={classes.marginAutoItem}>
                <CircularProgress />
              </div>
            </div>
          ) : (
            <React.Fragment>
              <Typography
                gutterBottom
                dangerouslySetInnerHTML={{ __html: modalMessage }}
              />
            </React.Fragment>
          )}
        </AlertDialog>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <TemporaryDrawer>
        {/* <MiniDrawer points={pointTotal} countdown={countdownTimer()}> */}
          <MainLayout>
            No Event has been selected! Go to <Link to="/events">Events</Link>{" "}
            to select one.
          </MainLayout>
        {/* </MiniDrawer> */}
      </TemporaryDrawer>
      </div>
    );
  }
}
