import GamesPage from "components/GamesPage/GamesPage";
import Home from "components/Home/Home";
import Login from "components/Auth/Login";
import Logout from "components/Auth/Logout";
import EventsPage from "components/Events/EventsPage";
import RankingsPage from "components/Rankings/RankingsPage";
import MainLayout from "components/Layouts/MainLayout";
import AppBarLayout from "components/Layouts/AppBarLayout";
import SignInPage from "components/SignIn/SignInPage";

import InstructionsPage from "pages/InstructionsPage";
import UpdateRankingsPage from "pages/UpdateRankingsPage";
import EventsLandingPage from "pages/EventLandingPage";

import ExtensionSharpIcon from '@material-ui/icons/ExtensionSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import CalendarTodaySharpIcon from '@material-ui/icons/CalendarTodaySharp';
import EmojiEventsSharpIcon from '@material-ui/icons/EmojiEventsSharp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';


export const routes = [
  {
    path: "/games",
    name: "Games",
    component: GamesPage,
    // layout: AppBarLayout,
    authenticationRequired: true
  },
  {
    path: "/events",
    name: "Events",
    component: EventsPage,
    authenticationRequired: true
  },
  {
    path: "/login",
    name: "Login",
    component: SignInPage,
    authenticationRequired: false,
    redirectIfLoggedIn: true,
    redirect_path: "/events",
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignInPage,
    authenticationRequired: false,
    redirectIfLoggedIn: false,
    redirect_path: "/events",
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    authenticationRequired: false,
  },
  {
    path: "/rankings",
    name: "Rankings",
    component: RankingsPage,
    authenticationRequired: true
  },
  {
    path: "/instructions",
    name: "Instructions",
    component: InstructionsPage,
    authenticationRequired: true
  },
  {
    path: "/rankingupdates",
    name: "Update Rankings",
    component: UpdateRankingsPage,
    authenticationRequired: true
  },
  {
    path: "/eventslanding",
    name: "Event Landing Page",
    component: EventsLandingPage,
    authenticationRequired: false
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    authenticationRequired: false
  },
];

export const routeLinks = [
  {
    path: "/",
    name: "Home",
    icon: HomeSharpIcon,
    authenticationRequired: false
  },
  {
    path: "/instructions",
    name: "Instructions",
    icon: FormatListNumberedIcon,
    authenticationRequired: true
  },
  {
    path: "/events",
    name: "Events",
    icon: CalendarTodaySharpIcon,
    authenticationRequired: true
  },
  {
    path: "/games",
    name: "Games",
    icon: ExtensionSharpIcon,
    authenticationRequired: true
  },
  {
    path: "/rankings",
    name: "Rankings",
    icon: EmojiEventsSharpIcon,
    authenticationRequired: true
  },
  {
    path: "/login",
    name: "Login",
    icon: EmojiEventsSharpIcon,
    component: SignInPage,
    authenticationRequired: false,
    onlyNonAuthenticated: true,
  },
  {
    path: "/logout",
    name: "Logout",
    icon: ExitToAppIcon,
    authenticationRequired: true,
  },
]
