import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

interface ProgressTimerProps {
  label: string,
  seconds: number,
  onComplete?: () => void;
}

export default function ProgressTimer(props:ProgressTimerProps) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress >= 100) {
        if(props.onComplete)
          props.onComplete()
        setProgress(0);
      } else {
        const diff = 100/props.seconds;
        setProgress(progress + diff);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgress variant="buffer" value={progress} />
    </div>
  );
}
