import React from "react";

type GameProps = {
  game?: React.ReactNode;
};

function Game({ game }: GameProps) {
  return (
    <div className="gametsx">
      {game}
    </div>
  )
}

export default Game;
