import React, { useState, useEffect } from "react";

import "./NumberSquare.css";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type Props = {
  id?: string;
  index: number;
  number?: number;
  setDigit: (index: number, number: number) => void;
};

const NumberSquare = (props: Props) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (props.number) {
      setNumber(props.number);
    }
  }, [number]);

  const onClickUp = (event: React.MouseEvent<SVGSVGElement>) => {
    console.log("onClick is being called");
    if (number === 9) {
      setNumber(0);
      props.setDigit(props.index, 0);
    } else {
      setNumber(number + 1);
      props.setDigit(props.index, number + 1);
    }
  };

  const onClickDown = (event: React.MouseEvent<SVGSVGElement>) => {
    console.log("onClick is being called");
    if (number === 0) {
      setNumber(9);
      props.setDigit(props.index, 9);
    } else {
      setNumber(number - 1);
      props.setDigit(props.index, number - 1);
    }
  };

  const displaySquare = () => {
    return number;
  };

  return (
    <div>
      <ExpandLessIcon onClick={onClickUp} fontSize="large" />
      <div className="number-square noselect" id={props.id}>
        {number}
      </div>
      <ExpandMoreIcon onClick={onClickDown} fontSize="large" />
    </div>
  );
};

export default NumberSquare;
