import React, { useState, useEffect } from "react";
import cx from "classnames";

import { useRouteMatch, useParams, Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PageBreadcrumbs from "components/Breadcrumbs/PageBreadcrumbs";

import CachedIcon from '@material-ui/icons/Cached';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import HearingIcon from '@material-ui/icons/Hearing';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import { IGameProps } from "components/Games/IGame";
import { IGameRouteProps } from "./IGameRoute";
import Game from "components/Games/Game";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1
  },
  titleRow: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center"
  },
  rightArrow: {
    flexGrow: 1,
    textAlign: "right"
  },
  leftArrow: {
    flexGrow: 1,
    textAlign: "left"
  },
  gameHeader: {
    'font-size': '3vw',
    // 'font-size': 'calc(0.875em + 0.25 * (100vw - 80em) / 40), -webkit-calc(87.5% + 0.25 * (100vw - 8000%) / 40), -ms-calc(0.875em + 0.25 * (100vw - 80em) / 40)',
    // 'white-space': 'nowrap'
  }
}));

function GameRoute({ games }: IGameRouteProps) {
  let { gameId } = useParams();
  let match = useRouteMatch();
  const classes = useStyles();
  gameId = Number(gameId);

  const [gameTitle, setGameTitle] = useState("");
  const [points, setPoints] = useState(0);
  const [uniqueId, setUniqueId] = useState("");
  const [hasAudio, setHasAudio] = useState(false);
  const [hasMissingPieces, setHasMissingPieces] = useState(false);
  const [hasLimitedAttempts, setHasLimitedAttempts] = useState(false);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [isTimed, setIsTimed] = useState(false);
  const [canChangeAnswer, setCanChangeAnswer] = useState(false);

  const [isComplete, setIsComplete] = useState(false);

  console.log("Game Id: ", gameId);

  const resetIcons = () => {
    setIsComplete(false);
    setHasLimitedAttempts(false);
    setIsTimed(false);
    setCanChangeAnswer(false);
    setHasAudio(false);
    setHasMissingPieces(false);
  }

  useEffect(() => {
    var game = React.Children.toArray(games).slice(
      gameId,
      gameId + 1
    )[0] as React.ReactElement<IGameProps>;

    if (game && game.props) {
      console.debug("Game: ", game.props);
      setGameTitle(game.props.title);
      setPoints(game.props.points);
      if (game.props.unique_id) {
        setUniqueId(game.props.unique_id);
      } else {
        setUniqueId("");
      }

      resetIcons();

      if(game.props.defaultPuzzleSettings) {
        setHasLimitedAttempts(game.props.defaultPuzzleSettings.allowed_number_of_tries > 0);
        setIsTimed(game.props.defaultPuzzleSettings.is_timed == true);
        setCanChangeAnswer(game.props.defaultPuzzleSettings.can_change_answer);
      }

      setHasAudio(game.props.audio != undefined);
      setHasMissingPieces(game.props.is_parent == true);

      if(game.props.progress?.is_complete) {
        setIsComplete(true);
      }

    }
  }, [games]);

  const displayIcons = () => {
    let icons = [];

    if(hasLimitedAttempts)
      icons.push(<FavoriteBorderOutlinedIcon />);

    if(isTimed)
      icons.push(<TimerIcon />);

    if(canChangeAnswer)
      icons.push(<CachedIcon />);

    if(hasAudio)
      icons.push(<HearingIcon />);

    if(hasMissingPieces)
      icons.push(<ExtensionOutlinedIcon />);


    return (<React.Fragment>{icons}</React.Fragment>)

  }

  return (
    <div className={cx({
      'justify-content': true,
      'mt-2': window.innerWidth > 640,
      'p-2': window.innerWidth > 640
    })}>
      <div>
        <PageBreadcrumbs
          mainPage={{ name: "Games", path: "/games" }}
          currentPage={{ name: gameTitle, path: "/" }}
        />
      </div>
      <div className={classes.titleRow}>
        <div className={classes.leftArrow}>
          {gameId > 0 ? (
            <Link
              to={{
                pathname: `/games/${gameId - 1}`
              }}
            >
              <ArrowBackIcon fontSize="large" />
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="game justify-content-center">
          {/* <div className={classes.gameHeader}> */}
          <h3>
            {gameTitle} {isComplete ? (<CheckBoxOutlinedIcon fontSize='large'/>) : (<CheckBoxOutlineBlankOutlinedIcon fontSize='large'/>)}
          </h3>
          {/* </div> */}
          {/* <div className={cx({
            'alert': true,
            'alert-primary': true
          })}> */}
          {points > 0 ? <h5 className='alert-primary'>{points} points {displayIcons()}</h5> : ""}
        {/* </div> */}
        </div>
        <div className={classes.rightArrow}>
          {gameId < React.Children.count(games) - 1 ? (
            <Link
              to={{
                pathname: `/games/${gameId + 1}`
              }}
            >
              <ArrowForwardIcon fontSize="large" />
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={cx("border", "border-dark", "pb-2", `${classes.grow}`)}>
        {console.debug(
          "Game1: ",
          React.Children.toArray(games).slice(gameId, gameId + 1)[0]
        )}
        <Game game={React.Children.toArray(games).slice(gameId, gameId + 1)} />
      </div>
    </div>
  );
}

export default GameRoute;
