import React from "react";

import MainLayout from "components/Layouts/MainLayout";
import BoardContainer from "components/MainContainer/BoardContainer";
import Container from '@material-ui/core/Container';

function GamesPage({ children }: { children: React.ReactNode }) {
  return (
    // <Container maxWidth="xl">
    // <React.Fragment>
      <BoardContainer />
    // </React.Fragment>
    // </Container>
  );
}

export default GamesPage;
