import React, { Children, useState, ReactElement, ReactNode } from "react";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import DisplayNavigation from "components/Navigation/DisplayNavigation"
import AppBar from "components/Navigation/AppBar"

import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginTop: 4,
      // border: '4px solid',
    },
    navigation: {
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  }),
);

type Props = {
  children?: ReactNode;
};

const StyledNavigation = styled(DisplayNavigation)({
  marginRight: 'auto',
  marginLeft: 'auto'
})

export default function MainLayout({ children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={3} direction="row" justify="center" alignItems="center">
        <Grid item> */}
          {/* <Paper className={classes.paper}> */}
              {children}
          {/* </Paper> */}
        {/* </Grid>
      </Grid> */}
    </div >
  );
}
