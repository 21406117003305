import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import MuiButton from "@material-ui/core/Button";
import GameLayout from "components/Layouts/GameLayout";
import GridTable from "components/Grid/GridTable";
import MessageModal from "components/Modal";
import { IGameProps, IGameState } from "./IGame";
import { useLocalStorage } from "components/Utils/Storage";
import TabLayout from "components/Layouts/TabLayout";

import { PuzzleTypes } from "components/Settings/PuzzleSettings";

import { GetPuzzleProgress } from "api/Calls";

import axios from "axios";
import uuid from "uuid";

interface GridGameState {
  showModal: boolean;
  isCorrect: boolean;
  message: string;
  answer: string;
}

const GridGame = (props: IGameProps) => {
  const [isCorrect, setIsCorrect] = useState(false);
  const [answer, setAnswer] = useState("123456789");
  const [progress, setProgress] = useState();
  const [isComplete, setIsComplete] = useLocalStorage(
    "is_complete-" + props.id,
    props.isComplete
  );

  const [resetFunction, setResetFunction] = useState<() => void>(() => () =>
    console.debug("default reset function")
  );

  const handleCheckAnswer = () => {
    console.log("Handle Check Answer click: ", isComplete);
    let isCorrect = props.checkAnswer.call(undefined, props.id, answer);
  };

  const updateAnswer = (answer: string) => {
    setAnswer(answer);
  };

  useEffect(() => {
    console.debug("Use Effect is being called in Grid Game");
    if (props.is_parent && props.progress) {
      let newProps = {
        puzzle_progress_id: props.progress?.id,
        setProgress: setProgress
      };
      GetPuzzleProgress(newProps);
    }
  }, []);

  const addButton = (): React.ReactElement | undefined => {
    // console.info("Game Type: ", props.gameType);
    if (props.gameType == PuzzleTypes.GridSelectGame) {
      return (
        <MuiButton
          variant="contained"
          color="primary"
          onClick={handleCheckAnswer}
          className="mr-1"
        >
          Submit
        </MuiButton>
      );
    }
    return undefined;
  };

  const addResetButton = () => {
    // console.info("Game Type: ", props.gameType);
    // console.debug("GridRef: ", gridRef, " GridRef.Current: ", gridRef.current);
    // console.debug("Creating Reset Button");
    return (
      <MuiButton
        variant="outlined"
        color="secondary"
        onClick={() => {
          if (resetFunction) {
            resetFunction();
          }
        }}
        className="mr-1"
      >
        Reset
      </MuiButton>
    );
  };

  // The key to this is that you must return a function that returns a function
  // () => () => function()
  const shareResetFunction = (functionToShare: () => void) => {
    setResetFunction(() => () => functionToShare());
  };

  return (
    <div className="mx-auto">
      {/* {console.debug("Progress: ", progress)} */}
      <div className="number-game justify-content-center">
        <TabLayout
          instructions={props.instructions}
          imageUrl={props.imageUrl}
          video={props.video}
          progress={progress ? progress: props.progress}
        >
          <GameLayout
            questionImageUrl={props.questionImageUrl}
            instructions={
              props.gameType == PuzzleTypes.GridGame
                ? "Click the cells in the correct order"
                : "Select the correct cells"
            }
            game={
              <GridTable
                useNumber={
                  props.gameType == PuzzleTypes.GridSelectGame ? false : true
                }
                checkAnswer={handleCheckAnswer}
                updateAnswer={updateAnswer}
                numberOfRows={3}
                numberOfCols={3}
                setResetClick={shareResetFunction}
              />
            }
            button={
              props.gameType == PuzzleTypes.GridSelectGame ? addButton() : ""
            }
            id={props.id}
            requestHint={props.requestHint}
            hasHint={props.has_hint}
            settings={props.defaultPuzzleSettings}
            progress={props.progress}
            resetButton={addResetButton()}
          />
        </TabLayout>
      </div>
      <div className="d-flex flex-row grid-game justify-content-center"></div>
    </div>
  );
};
export default GridGame;
