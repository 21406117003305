import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CachedIcon from "@material-ui/icons/Cached";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import TimerIcon from "@material-ui/icons/Timer";
import HearingIcon from "@material-ui/icons/Hearing";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";

import MainLayout from "components/Layouts/MainLayout";
import MiniDrawer from "components/Navigation/MiniDrawer";
import TemporaryDrawer from "components/Navigation/TemporaryDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "left"
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column"
    }
  })
);

function InstructionsPage({ children }: { children: React.ReactNode }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TemporaryDrawer>
      {/* <MiniDrawer> */}
        <MainLayout>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography variant="h4" paragraph align="left">
                Instructions
              </Typography>
              <Typography variant="body1" paragraph align="left">
                <i>
                  Before we get started, please take a few minutes to read
                  through these instructions. They’ll provide you with essential
                  information on how to navigate the site and gain points.
                </i>
              </Typography>
              <Typography variant="h6" align="left">
                About the Event
              </Typography>
              <Typography variant="body1" paragraph align="left">
                Welcome to The Challenge! You and your teammates will be completing
                challenges and solving puzzles in exchange for points that will rank
                you against the rest of your group. Points are earned for successfully
                submitting the correct answer to individual games, which are assigned
                different point values based on their difficulty.
              </Typography>
              <Typography variant="h6" align="left">
                The Games
              </Typography>
              <Typography variant="body1" paragraph align="left">
                The puzzles range from fairly simple to challenging and may include some tri
                via about your company and colleagues. Once the event has started, they can
                be accessed by clicking “Games” in the menu. Click on a game to view it,
                then click over to the “Submit Answer” tab in the game to submit your answer.
                Game types are indicated by the following icons:
              </Typography>

              <ul style={{ textAlign: "left" }}>
                <li>{<FavoriteBorderOutlinedIcon />}: You will have a limited number of attempts to submit the correct answer</li>
                <li>{<TimerIcon />}: You will have a limited amount of time to submit the correct answer</li>
                <li>{<ExtensionOutlinedIcon />}: These puzzles will remain locked until you complete other puzzles</li>
                <li>{<HearingIcon />}: These puzzles will require you to listen to an audio file</li>
                <li>{<PhoneInTalkOutlinedIcon />}: These puzzles will require you to call the provided phone number</li>
              </ul>

              <Typography variant="h6" align="left">
                Rankings
              </Typography>
              <Typography variant="body1" paragraph align="left">
                At any point during the event, click “Rankings” in the menu to view how
                you’re stacking up against others and see how many points you’ll need to get to move up.
              </Typography>

              <Typography variant="h6" align="left">
                Hints
              </Typography>
              <Typography variant="body1" align="left">
                To get a hint, navigate to the “Submit Answer” tab of the puzzle you need help with and
                click “Request Hint”. If you request a hint, the maximum amount of points you will be able
                to gain from the game will be two-thirds of the total points available in that game.
              </Typography>
              {/* <Typography variant="body1" align="left">
                The following prizes will be awarded to the teams that finish in
                the top three:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" align="left">
                    First Place: 3x $20 Amazon Gift Cards
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" align="left">
                    Second Place: 3x $15 Amazon Gift Cards
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" align="left">
                    Third Place: 3x $10 Amazon Gift Cards
                  </Typography>
                </li>
              </ul> */}
              <Typography variant="h6" align="left" display="inline">
                Strategy
              </Typography>
              <Typography variant="body1" paragraph align="left">
                It’s up to your team to decide how to best tackle the puzzles - will you all work on each
                puzzle together or will you split up into smaller groups? Remember that you only have a
                limited amount of time to earn as many points as possible, so if you’re stuck you should
                consider taking a hint or moving on to a new question. Most teams don’t complete all
                puzzles within the time limit, so focus your attention on the puzzles you are able to solve.
                And keep in mind - the harder the puzzle, the higher the reward!
              </Typography>
              <Typography variant="h6" align="left">
                Getting Started
              </Typography>
              <Typography variant="body1" paragraph align="left">
                Once you or one of your teammates clicks “Start Event” on the Events page,
                 the timer for your team will begin and you will have a limited amount of
                 time to gain as many points as possible. Your number of points and time
                 remaining will be displayed in the menu at the top of every page. Do not
                 click “Start Event” until all of your teammates are ready!
              </Typography>
              <Typography variant="h6" align="left">
                Final Standings
              </Typography>
              <Typography variant="body1" paragraph align="left">
                After the event ends for all teams, the final results will be announced.
              </Typography>
            </div>
          </Container>
        </MainLayout>
      {/* </MiniDrawer> */}
      </TemporaryDrawer>
    </div>
  );
}

export default InstructionsPage;
