import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import MuiButton from "@material-ui/core/Button";
import TabLayout from "components/Layouts/TabLayout";
import GameLayout from "components/Layouts/GameLayout";
import MultipleNumbers from "components/Number/MultipleNumbers";
import MessageModal from "components/Modal";
import { IGameProps } from "./IGame";

import axios from "axios";
import uuid from "uuid";

const NumberGame = (props: IGameProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [message, setMessage] = useState("Incorrect");
  const [myAnswer, setMyAnswer] = useState("");
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);

  const [resetFunction, setResetFunction] = useState<() => void>(() => () => console.debug("default reset function"));

  useEffect(() => {
    if (props.progress) {
      setNumberOfAttempts(props.progress.number_of_attempts);
    }
  }, [props.progress?.number_of_attempts]);

  const handleAnswerChange = (answer: string) => {
    setMyAnswer(answer);
  };

  const handleCheckAnswer = () => {
    let isCorrect = props.checkAnswer.call(undefined, props.id, myAnswer);
  };

  const getAnswer = (answer: string) => {
    return answer;
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const addResetButton = () => {
    // console.info("Game Type: ", props.gameType);
    // console.debug("GridRef: ", gridRef, " GridRef.Current: ", gridRef.current);
    // console.debug("Creating Reset Button");
    return (
      <Button
        color="primary"
        className="mr-2"
        onClick={() => {
          if(resetFunction) {
            resetFunction();
          }
        }}
      >
        Reset
      </Button>
    );
  };

// The key to this is that you must return a function that returns a function
// () => () => function()
  const shareResetFunction = (functionToShare: () => void) => {
    setResetFunction(() => () => functionToShare());
  }

  return (
    <div className="mx-auto">
      <div className="number-game justify-content-center">
        <TabLayout
          instructions={props.instructions}
          imageUrl={props.imageUrl}
          video={props.video}
          audio={props.audio}
        >
          <GameLayout
            questionImageUrl={props.questionImageUrl}
            game={
              <MultipleNumbers
                numberOfDigits={props.answer_length}
                onChange={handleAnswerChange}
                setResetClick={shareResetFunction}
              />
            }
            button={
              <MuiButton
                variant="contained"
                color="primary"
                onClick={handleCheckAnswer}
                className="mr-1"
              >
                Submit
              </MuiButton>
            }
            id={props.id}
            hasHint={props.has_hint}
            requestHint={props.requestHint}
            numberOfAttempts={numberOfAttempts}
            settings={props.defaultPuzzleSettings}
            progress={props.progress}
          />
        </TabLayout>
      </div>
    </div>
  );
};
export default NumberGame;
