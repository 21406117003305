import React, { useEffect, useState } from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CachedIcon from "@material-ui/icons/Cached";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import TimerIcon from "@material-ui/icons/Timer";
import HearingIcon from "@material-ui/icons/Hearing";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Dialog from "components/Dialog";

import MainLayout from "components/Layouts/MainLayout";
import MiniDrawer from "components/Navigation/MiniDrawer";
import { Rankings } from "components/Rankings/Rankings";
import { axiosInstance } from "api/axiosApi";
import { useLocalStorage, useMapState } from "components/Utils/Storage";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "left"
    },
    paper: {
      marginTop: theme.spacing(0),
      display: "flex",
      flexDirection: "column"
    }
  })
);

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  })
);

interface Props {
  children: React.ReactElement<any>[] | React.ReactElement<any>;
}

function UpdateRankingsPage({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  const [eventId, setEventId] = useLocalStorage("current_event_id", -1);
  const [gameDataToUse, setGameDataToUse] = useState([]);
  const [updateNumber, setUpdateNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const classes2 = useStyles2();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [completedIndicies, setCompletedIndicies] = useState<boolean[]>([]);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const refreshList = async () => {
    axiosInstance
      .get("event/" + eventId + "/admin/endpuzzles?format=json")
      .then(result => {
        console.log("Received data: ", result.data);
        console.log("Received data Length: ", result.data.length);
        setGameDataToUse(result.data);
        setCompletedIndicies(new Array(result.data.length).fill(false));
        console.log("Puzzles: ", result.data);
      })
      .catch(err => {
        console.log("There was an error");
        console.log(err);
      });
  };

  const addExtraPoints = async (index: number, puzzle_id: number) => {
    axiosInstance
      .post("event/addextrapoints", {
        event_id: eventId,
        puzzle_id: puzzle_id
      })
      .then(result => {
        console.log("Received answer: ", result.data.answer);
        setModalMessage(result.data.answer);
        setShowModal(true);
        completedIndicies[index] = true;
      })
      .catch(err => {
        console.log("There was an error");
        console.log(err);
      });
  };

  useEffect(() => {
    refreshList();
  }, []);

  useEffect(() => {}, [gameDataToUse, updateNumber]);

  const dataToGames = () => {
    console.debug("Data to Games: ", gameDataToUse);
    let games: React.ReactElement[] = [];

    if (gameDataToUse) {
      gameDataToUse?.map((game: any, index: number) => {
        if (completedIndicies[index] == false) {
          games.push(
            <Accordion
              expanded={expanded === "panel" + index}
              onChange={handleChange("panel" + index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes2.heading}>
                  {game.display_name} [{game.points} points]
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <img src={game.image.upload_url} style={{ width: "100%" }} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      addExtraPoints(index, game.id);
                      console.debug("Click Button for id: ", game.id);
                    }}
                    className="mr-1"
                  >
                    Show Answer
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        }
      });
    }

    console.debug("Games: ", games);
    return games;
  };

  const test = () => {
    let games: React.ReactElement[] = [];
    games.push(<div>Hello</div>);
    return games;
  };

  const handleClose = () => {
    setShowModal(false);
    setUpdateNumber(updateNumber + 1);
  };

  const handleAccept = () => {
    setShowModal(false);
    setUpdateNumber(updateNumber + 1);
  };

  return (
    <div className={classes.root}>
      <MiniDrawer>
        <MainLayout>
          <CssBaseline />
          <div className={classes.paper}>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <h4>Puzzles</h4>
                  <Grid item>
                    <div className={classes2.root}>{dataToGames()}</div>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <h4>Rankings</h4>
                  <Rankings refresh={updateNumber} showChange />
                </Paper>
              </Grid>
            </Grid>
          </div>

          <Dialog
            visible={showModal}
            handleClose={handleClose}
            title="The answer is..."
            buttonText="Add Points"
            body={modalMessage}
          />
        </MainLayout>
      </MiniDrawer>
    </div>
  );
}

export default UpdateRankingsPage;
