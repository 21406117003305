import React, { useState, useEffect } from "react";
import cx from "classnames";

import { ILimitedSelectionGroupOrders } from "components/Games/IGame";

import LetterSquare from "./LetterSquare";

type Props = {
  numberOfLetters: number;
  onChange: (newString: string) => void;
  limitedLetters?: ILimitedSelectionGroupOrders[];
  setResetClick?: Function;
};

const MultipleLetters = (props: Props) => {
  const [numberOfLetters, setNumberOfLetters] = useState(
    props.numberOfLetters ? props.numberOfLetters : 4
  );
  const [letterArray, setLetterArray] = useState(
    Array(props.numberOfLetters).fill("A")
  );
  const [letterArrayString, setLetterArrayString] = useState("");

  useEffect(() => {
    if (props.setResetClick) {
      props.setResetClick(reset);
    }
  }, []);

  const onClick = (event: React.FormEventHandler<HTMLInputElement>) => {};

  const reset = () => {
    setNumberOfLetters(props.numberOfLetters);
    setLetterArray(Array(props.numberOfLetters).fill("A"));
    setLetterArrayString("");
  };

  const changeAnswer = (index: number, number: string) => {
    const newAnswer = [
      ...letterArray.slice(0, index - 1),
      number,
      ...letterArray.slice(index)
    ];
    setLetterArray(newAnswer);
    const newLetterArrayString = newAnswer.map(letter => letter).join("");
    setLetterArrayString(newLetterArrayString);
    props.onChange(newLetterArrayString);
  };

  const createNumbers = () => {
    let numbers = [];
    for (
      let letterNumber = 1;
      letterNumber <= numberOfLetters;
      letterNumber++
    ) {
      numbers.push(
        <div className={cx({
          'm-1': window.innerWidth > 640,
        })} key={letterNumber}>
          <LetterSquare
            key={letterNumber}
            index={letterNumber}
            setLetter={changeAnswer}
            limitedSelections={
              props.limitedLetters && props.limitedLetters.length > 0
                ? props.limitedLetters[letterNumber - 1].limited_selection_group
                    .limited_selections
                : undefined
            }
          />
        </div>
      );
    }

    return <div className="d-flex flex-row">{numbers}</div>;
  };

  console.debug("Props: ", props);
  return (
    <div>
      {/* <div className="row">{letterArray}</div> */}
      <div className="row">{createNumbers()}</div>
    </div>
  );
};

export default MultipleLetters;
