import React, { Component } from "react";

import Container from "@material-ui/core/Container";
import GameRoom from "./components/GameRoom/GameRoom";
import BoardContainer from "./components/MainContainer/BoardContainer";
import {
  PrivateRoute,
  isAuthenticated
} from "components/Auth/AuthenticatedRoute";
import MainLayout from "./components/Layouts/MainLayout";
import logo from "./logo.svg";

import AOS from "aos";

import "./App.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
// import 'leaflet/dist/leaflet.css';
// import 'assets/css/index.css';

// import 'swiper/css/swiper.min.css';
import "aos/dist/aos.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { red, blue } from "@material-ui/core/colors";
import theme from "./theme";

import { routes } from "components/Routes";

function RouteWithSubRoutes(route) {
  if (route.authenticationRequired) {
    return (
      <Route
        path={route.path}
        render={props =>
          isAuthenticated() ? (
            // pass the sub-routes down to keep nesting
            route.layout ? (
              <route.layout>
                <route.component {...props} routes={route.routes} />
              </route.layout>
            ) : (
              <route.component {...props} routes={route.routes} />
            )
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  } else {
    if (route.redirectIfLoggedIn && isAuthenticated()) {
      return (
        <Route
          path={route.path}
          render={props => (
            // pass the sub-routes down to keep nesting
            <Redirect to={route.redirect_path} />
          )}
        />
      );
    }
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }
}

const theme2 = createMuiTheme({
  palette: {
    primary: {
      main: "#1e3a97"
      // main: '#207697',
    },
    secondary: {
      main: "#1e977b"
      // main: '#651fff',
    }
  },
  menu: {
    main: "#1e7697"
  }
});

const App = () => {
  AOS.init({ once: true, delay: 50, duration: 500, easing: "ease-in-out" });

  return (
    <Container maxWidth="md">
      <Router>
        {/* <MainLayout> */}
        <ThemeProvider theme={theme}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </ThemeProvider>
        {/* </MainLayout> */}
      </Router>
    </Container>
  );
};

export default App;
