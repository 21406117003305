import React, { useState, useEffect } from "react";

import classNames from "classnames";

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";

import AlertDialog from "components/AlertDialog";
import { useLocalStorage } from "components/Utils/Storage";
import { axiosInstance } from "api/axiosApi";

import { IEventProgress } from "./IEventProgress";
import { DisplayEvent } from "./Event";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
      marginTop: 4
    },
    gridList: {
      width: "xs",
      height: "xs",
      margin: '10px !important'
    },
    gridListTile: {
      "& .MuiGridListTile-tile": {
        border: "4px solid",
      },
      "&:hover": {
        border: "4px solid",
      }
    },
    gridListTileComplete: {
        "& .MuiGridListTile-tile": {
          border: "4px solid green",
        },
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)"
    },
    eventImage: {
      'max-width': '100%',
      'max-height': '100%',
    },
    mainIcon: {
      // fontSize: 10em,
    }
  })
);

export default function Events() {
  const classes = useStyles();
  const history = useHistory();
  const [currentEventId, setCurrentEventId] = useLocalStorage(
    "current_event_id",
    1
  );
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const refreshList = async () => {
      axiosInstance
        .get("eventprogresses/?format=json")
        .then(result => {
          console.log("Received data: ", result.data);
          console.log("Received data Length: ", result.data.length);
          setEvents(result.data);
        })
        .catch(err => {
          console.log("There was an error");
          console.log(err);
        });
    };
    refreshList();
  }, []);

  const displayEvents = () => {
    if (events) {
      return (
        <div>
          <h1>My Events</h1>
          <div>
            <div className={classes.root}>
              {/* <GridList cellHeight={200} spacing={20} className={classes.gridList} cols={window.innerWidth < 640 ? 1 : 3}> */}
              <GridList cellHeight={200} spacing={20} className={classes.gridList} cols={1}>
                {console.log("Events: ", events)}
                {events.map((eventProgress: IEventProgress) => (
                  <GridListTile
                    cols={1}
                    className={classNames(classes.gridListTile, {
                      [`${classes.gridListTileComplete}`]: eventProgress.is_complete
                    })}
                    onClick={() => {
                      history.push(`/events/${eventProgress.id}`);
                    }}
                    key={eventProgress.event_name}
                  >
                    <img className={classes.eventImage} src={eventProgress.event.image?.upload_url} layout-fill />
                    <GridListTileBar
                      title={eventProgress.event_name}
                      subtitle={eventProgress.start_date_time}
                    />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </div>
        </div>
      );
    }
  };

  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/:eventProgressId`}>
        <DisplayEvent events={events} />
      </Route>
      <Route exact path="/events">
        {displayEvents()}
      </Route>
    </Switch>
  );
}
