import React, { useEffect } from 'react';

// User has switched back to the tab
// const onFocus = () => {
//   console.log('Tab is in focus');
// };
//
// // User has switched away from the tab (AKA tab is hidden)
// const onBlur = () => {
//   console.log('Tab is blurred');
// };

interface Props {
  onFocusF: () => void;
  onBlurF: () => void;
}

const WindowFocusHandler = ({onFocusF, onBlurF}: Props) => {
  useEffect(() => {
    window.addEventListener('focus', onFocusF);
    window.addEventListener('blur', onBlurF);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocusF);
      window.removeEventListener('blur', onBlurF);
    };
  });

  return <></>;
};

export default WindowFocusHandler;
