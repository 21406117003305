import React, {useState, useEffect} from "react";
import { Button } from "reactstrap";
import MuiButton from "@material-ui/core/Button";
import TabLayout from "components/Layouts/TabLayout";
import GameLayout from "components/Layouts/GameLayout";
import MultipleChoice from "components/MultipleChoice/MultipleChoice";
import {IGameProps} from "./IGame";

const MultipleChoiceGame = (props: IGameProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [message, setMessage] = useState("Incorrect");
  const [myAnswer, setMyAnswer] = useState("");
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    console.debug("MC Use Effect, props: ", props);
    if (props.progress) {
      setNumberOfAttempts(props.progress.number_of_attempts);

      if(props.defaultPuzzleSettings && props.defaultPuzzleSettings.allowed_number_of_tries <= props.progress.number_of_attempts) {
        console.debug("MC Maximum number of tries has been reached");
        setIsActive(false);
      }
    }
  }, [props.progress?.number_of_attempts]);

  const handleAnswerChange = (answer: string) => {
    setMyAnswer(answer);
  };

  const handleCheckAnswer = () => {
    let isCorrect = props.checkAnswer.call(undefined, props.id, myAnswer);
    // console.log("is correct: ", isCorrect)
    // console.info(
    //   `Checking ${.myAnswer} with the answer: ${props.answer}`
    // );
    // // if (.myAnswer === props.answer) {
    // if (isCorrect) {
    //   console.info("That answer is correct!");
    //   setState({ isCorrect: true });
    //   setState({ message: "Correct" });
    // } else {
    //   console.info("That answer is incorrect!");
    //   setState({ message: "Incorrect" });
    // }
    // setState({ showModal: true });
  };

  const getAnswer = (answer : string) => {
    return answer;
  };

  const handleClose = () => {
    setShowModal(false);
  };

    return (
      <div className="mx-auto">
        <div className="number-game justify-content-center">
          <TabLayout instructions={props.instructions} imageUrl={props.imageUrl} video={props.video}>
            <GameLayout
              questionImageUrl={props.questionImageUrl}
              game={<MultipleChoice
                    answer_options={props.answerOptions ? props.answerOptions : Array(1).fill({answer:'default'})}
                    onChange={handleAnswerChange}
              />}
              button={
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={handleCheckAnswer}
                  className="mr-1"
                >
                  Submit
                </MuiButton>}
              id={props.id}
              hasHint={props.has_hint}
              requestHint={props.requestHint}
              numberOfAttempts={numberOfAttempts}
              settings={props.defaultPuzzleSettings}
              progress={props.progress}
            />
          </TabLayout>
        </div>
      </div>
    );
}
export default MultipleChoiceGame;
