import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Link from '@material-ui/core/Link';
import {
  Link
} from "react-router-dom";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  // event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

interface Props {
  mainPage: {name: string, path: string};
  currentPage: {name: string, path: string};
}

export default function PageBreadcrumbs(props: Props) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" to={props.mainPage.path} onClick={handleClick}>
        {props.mainPage.name}
      </Link>
      <Typography color="textPrimary">{props.currentPage.name}</Typography>
    </Breadcrumbs>
  );
}
