import React from "react";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";

const url = "https://api.virtualteambuilding.io/api/"
// const url = "https://virtualteambuilding.ngrok.io/api/"

export const axiosInstance = axios.create({
  baseURL: url,
  timeout: 5000,
  headers: {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    "Content-Type": "application/json",
    accept: "application/json"
  }
});

export const axiosLoginInstance = axios.create({
  baseURL: url,
  timeout: 5000,
  headers: {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    "Content-Type": "application/json",
    accept: "application/json"
  }
});

// This looks at responses before everything else does, so if in this instance
// a 401 response is received it will attempt to reauthenticate
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    console.debug(
      "Attempting to refresh token because of code: ",
      error.response.status,
      " and status text: ",
      error.response.statusText
    );

    if (
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refresh_token = localStorage.getItem("refresh_token");
      console.debug("Meets Unauthorized conditions");

      return axiosInstance
        .post("token/refresh/", { refresh: refresh_token })
        .then(response => {
          localStorage.setItem("access_token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);

          axiosInstance.defaults.headers["Authorization"] =
            "JWT " + response.data.access;
          originalRequest.headers["Authorization"] =
            "JWT " + response.data.access;

          return axiosInstance(originalRequest);
        })
        .catch(err => {
          console.log(err);
        });
    }
    return Promise.reject(error);
  }
);

// const history = useHistory();

function redirectToLogin() {
  // window.location.replace(
  // `${getConfig().LOGIN_URL}?next=${window.location.href}`
  // )
  // or history.push('/login') if your Login page is inside the same app

  return <Redirect to="/login" />;
}
