import React, { Component, useState } from "react";
import { Redirect } from "react-router-dom";
import { axiosInstance } from "api/axiosApi";

const Logout = () => {
  const logout = () => {
    localStorage.clear();
  };

  console.debug("Clearing local storage");
  localStorage.clear();
  return (<Redirect to="/" />);

};

export default Logout;
