import React, { useEffect } from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { isAuthenticated } from "components/Auth/AuthenticatedRoute";

import { useHistory } from "react-router-dom";

import { routes, routeLinks } from "components/Routes";
import { useLocalStorage } from "components/Utils/Storage";
import { MyTheme } from "components/Theme/Theme";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    grow: {
      flexGrow: 1
    },
    outer: {
      alignSelf: 'stretch',
      display: "flex"
    },
    inner: {
      alignSelf: 'center',
      display: "flex",
      marginRight: "auto",
      marginLeft: "auto",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: "#1e7697",
      color: "white"
    },
    appBarShift: {
      marginLeft: drawerWidth
      // width: `calc(100% - ${drawerWidth}px)`,
      // transition: theme.transitions.create(["width", "margin"], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.enteringScreen
      // })
    },
    menuButton: {
      marginRight: 36
    },
    welcomeUser: {
      textAlign: "right",
      flexGrow: 1
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  })
);

export default function MiniDrawer({
  points,
  countdown,
  children
}: {
  points?: number;
  countdown?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  // const [teamName, setTeamName] = useLocalStorage('team_name', '');
  const [teamName, setTeamName] = React.useState(
    localStorage.getItem("team_name")
  );

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const history = useHistory();

  const buildLinks = () => {
    var links: any[] = [];
    var showAuthenticated = isAuthenticated();
    routeLinks.map((route, i) => {
      if (
        (route.authenticationRequired && showAuthenticated) ||
        !route.authenticationRequired
      ) {
        if (route.onlyNonAuthenticated == undefined || (route.onlyNonAuthenticated && !isAuthenticated())) {
          links.push(
            <ListItem
              button
              key={route.name}
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault();
                history.push(route.path);
              }}
            >
              <ListItemIcon>
                <route.icon />
              </ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          );
        }
      }
    });

    return links;
  };

  const displayCountdown = () => {
    if (countdown) {
      return (
        <React.Fragment>
          TIME REMAINING:
          {countdown}
        </React.Fragment>
      );
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClick}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            VirtualTeamBuilding.io
          </Typography>
          {points ? (
            <div className={classes.grow + " " + classes.outer}><div className={classes.inner}>TOTAL POINTS: {points}</div></div>
          ) : (
            ""
          )}
          <div className={classes.grow}>{displayCountdown()}</div>
          {teamName ? (
            <div className={classes.welcomeUser}>Welcome, {teamName}</div>
          ) : (
            ""
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{buildLinks()}</List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
