import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center"
    },
    fieldset: {
      flexGrow: 1,
    },
    radioGroup: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    label: {
      border: '1px solid',
      // flexGrow: 1,
      width: '45%'
    },
  })
);

interface AnswerProps {
  answer: string;
}

interface MutlipleChoiceProps {
  answer_options: { answer: string }[];
  onChange: (answer: string) => void;
}

export default function MultipleChoice(props: MutlipleChoiceProps) {
  const [value, setValue] = useState("");
  const [answers, setAnswers] = useState<AnswerProps[]>([]);

  const classes = useStyles();

  useEffect(() => {
    setAnswers(props.answer_options);
  }, [props.answer_options]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = (event.target as HTMLInputElement).value;
    setValue(newValue);
    props.onChange(newValue);
  };

  const createChoices = () => {
    let answerOptions: React.ReactElement[] = [];
    if (answers.length > 0) {
      answers.map((answer: AnswerProps, i) => {
        answerOptions.push(
          <FormControlLabel value={answer.answer} control={<Radio color="primary" checkedIcon={<CheckCircleIcon />}/>} label={answer.answer} className={classes.label}/>
        );
      });
    }
    return answerOptions;
  };

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      <FormLabel component="legend">Select Answer</FormLabel>
      <RadioGroup
        aria-label="answers"
        name="answer_gorup"
        value={value}
        onChange={handleChange}
        className={classes.radioGroup}
      >
        {createChoices()}
        {/* <FormControlLabel value={answers[0].answer} control={<Radio />} label={answers[0].answer} /> */}
      </RadioGroup>
    </FormControl>
  );
}
