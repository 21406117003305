import React, {useState, useEffect} from "react";
import GridCell from "./GridCell";
// import Header from "./Header";
// import InputTodo from "./InputTodo";

import axios from "axios";
import uuid from "uuid";

type Props = {
  numberOfCols: number;
  numberOfRows: number;
  useNumber: boolean;
  checkAnswer: Function;
  updateAnswer: Function;
  setResetClick?: Function;
};

const GridTable = (props: Props) => {
  const [number, setNumber] = useState(1);
  const [answer, setAnswer] = useState(Array(props.numberOfCols*props.numberOfRows).fill(0));
  const [answerString, setAnswerString] = useState("000000000");
  const [reset, setReset] = useState('F');
  const [numberOfCells, setNumberOfCells] = useState(props.numberOfCols*props.numberOfRows);
  const [useNumber, setUseNumber] = useState(props.useNumber ? props.useNumber : false);

  useEffect(() => {
    console.log("Component did update... ", answer);
    // const answerStringS = answer.map((digit) => digit).join('');
    if (number > numberOfCells) {
      console.log("Answer string: ", answerString);
      checkAnswer();
    }
  })

  useEffect(() => {
    if(props.setResetClick) {
      props.setResetClick(resetGrid);
    }
  }, [])

  const getNumber = () => {
    return number;
  }

  const updateList = (index : number, number : number) => {
    console.log("Updating Answer");
    console.log("Index: ", index, " Number: ", number);

    var numberToUse = (props.useNumber) ? number : number

    const newAnswer = [...answer.slice(0, index-1), numberToUse, ...answer.slice(index)]

    if(props.useNumber)
      var nextNumber = number + 1;
    else
      var nextNumber = 1;

    console.log("Answer: ", newAnswer);
    setAnswer(newAnswer)

    let answerString = newAnswer.map((digit) => digit).join('')

    setAnswerString(newAnswer.map((digit) => digit).join(''))

    if(nextNumber > numberOfCells + 1) {
        nextNumber = 1;
    }
    setNumber(nextNumber)

    props.updateAnswer.call(undefined, answerString)
  }

  const checkAnswer = () => {
    props.checkAnswer.call(undefined, answerString)

    resetGrid();

    return true;
  }

  const resetGrid = () => {
    console.debug("Resetting grid...");
    setAnswer(Array(numberOfCells).fill(0));
    setAnswerString("000000000");
    setNumber(1);
  }

  const createTable = () => {
    let grid = [];

    for (let row = 0; row < props.numberOfRows; row++) {
        let children = [];
        for (let col = 1; col <= props.numberOfCols; col++) {
          children.push(<GridCell
                          id={row*props.numberOfCols + col}
                          key={row*props.numberOfCols + col}
                          answerList={answer}
                          currentNumber={getNumber}
                          listHandler={updateList}
                          useNumber={useNumber}/>)
        }

        grid.push(<tr>{children}</tr>);
    }

    return grid
  }

    return (
      <div>
      {/* <div>{answer.slice(0,3)}</div>
      <div>{answer.slice(3,6)}</div>
      <div>{answer.slice(6,10)}</div>
      <div>Answer String: {answerString}</div>
      <div>Current Number: {getNumber()}</div> */}
      <table className="grid-table" id="our_table">
        <tbody>
          {createTable()}
        </tbody>
      </table>
      </div>
    );
}
export default GridTable;
