import React, { Children, useState, ReactElement, useEffect } from "react";
import { useLocalStorage, useMapState } from "components/Utils/Storage";

import { PuzzleTypes } from "components/Settings/PuzzleSettings";
import { IGameProgressProps } from "components/Games/IGame";
import { axiosInstance } from "./axiosApi";

export const GetPuzzleProgress = (props: {
  puzzle_progress_id: number;
  setProgress: Function;
}) => {
  axiosInstance
    .get("puzzleprogress/" + props.puzzle_progress_id + "?format=json")
    .then(result => {
      console.debug(
        "Received Puzzle Progress Data for Puzzle Progress Id: ",
        props.puzzle_progress_id,
        " - ",
        result.data
      );

      props.setProgress(result.data);
      return "Something";
    })
    .catch(err => {
      console.log("There was an error");
      console.log(err);
    });
};
