import React, { useState, useEffect } from "react";
import cx from "classnames";
import NumberSquare from "./NumberSquare";

type Prop = {
  numberOfDigits: number;
  onChange: Function;
  setResetClick?: Function;
}

const MultipleNumber = (props: Prop) => {
  const [numberOfDigits, setNumberOfDigits] = useState(
    props.numberOfDigits ? props.numberOfDigits : 4
  );
  const [digitArray, setDigitArray] = useState(
    Array(props.numberOfDigits).fill(0)
  );
  const [digitArrayString, setDigitArrayString] = useState("");

  useEffect(() => {
    if(props.setResetClick) {
      props.setResetClick(reset);
    }
  }, []);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {};

  const reset = () => {
    console.debug("Resetting numbers...");
    setNumberOfDigits(props.numberOfDigits);
    setDigitArray(Array(props.numberOfDigits).fill(0));
    setDigitArrayString("");
  }

  const changeAnswer = (index: number, number :number) => {
    const newAnswer = [
      ...digitArray.slice(0, index - 1),
      number,
      ...digitArray.slice(index),
    ];
    setDigitArray(newAnswer);
    const newDigitArrayString = newAnswer.map((digit) => digit).join("");
    setDigitArrayString(newDigitArrayString);
    props.onChange(newDigitArrayString);
  };

  const createNumbers = () => {
    let numbers = [];
    for (let digitNumber = 1; digitNumber <= numberOfDigits; digitNumber++) {
      numbers.push(
        <div className={cx({
          'm-1': window.innerWidth > 640,
        })} key={digitNumber}>
          <NumberSquare key={digitNumber} index={digitNumber} setDigit={changeAnswer} />
        </div>
      );
    }

    return <div className="d-flex flex-row">{numbers}</div>;
  };

  return (
    <div>
      {/* <div className="row">{digitArray}</div> */}
      {createNumbers()}
    </div>
  );
}

export default MultipleNumber;
