import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CachedIcon from "@material-ui/icons/Cached";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import TimerIcon from "@material-ui/icons/Timer";
import HearingIcon from "@material-ui/icons/Hearing";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";

// import WebBasic from "components/WebBasic/WebBasic";

import MainLayout from "components/Layouts/MainLayout";
import MiniDrawer from "components/Navigation/MiniDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "left"
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column"
    }
  })
);

const EventsLandingPage = () => {
  return (
    <div className="container">
      {/* <WebBasic /> */}
    </div>
  );
};

function EventsLandingPage2({ children }: { children: React.ReactNode }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MiniDrawer>
        <MainLayout>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography variant="h4" paragraph align="left">
                Instructions
              </Typography>
              <Typography variant="body1" paragraph align="left">
                <i>
                  Before we get started, please take a few minutes to read
                  through these instructions. They’ll provide you with essential
                  information on how to navigate the site and gain points.
                </i>
              </Typography>
              <Typography variant="h6" align="left">
                About the Event
              </Typography>
              <Typography variant="body1" paragraph align="left">
                Welcome to The Challenge! You and your teammates will be
                completing challenges and solving puzzles in exchange for points
                that will rank you against the rest of your group. Points are
                earned for successfully submitting the correct answer to
                individual games, which are assigned different point values
                based on their difficulty.
              </Typography>
              <Typography variant="body1" paragraph align="left">
                This event was designed to be completed on a computer (not a
                phone or tablet) and works best in an up-to-date browser
                (Chrome, Edge, or Firefox are preferred).
              </Typography>
              <Typography variant="h6" align="left">
                The Games
              </Typography>
              <Typography variant="body1" paragraph align="left">
                The puzzles range from fairly simple to challenging and include
                some trivia about your company and colleagues. Once the event
                has started, they can be accessed by clicking “Games” in the
                menu. Click on a game to view it, then click over to the “Submit
                Answer” tab in the game to submit your answer.
              </Typography>
              <Typography variant="body1" paragraph align="left">
                Some games will have restrictions, which will be indicated on
                the Games page. Some will only allow you a certain number of
                attempts at submitting an answer (indicated by{" "}
                {<FavoriteBorderOutlinedIcon />}), and others will give you a
                limited amount of time to submit the correct answer (indicated
                by {<TimerIcon />}). If you exceed the number of tries or the
                time limit you will be unable to continue working on the game
                and it will become unavailable, so use caution when working on
                these! A couple of games have information that will remain
                locked until you complete other games (indicated by{" "}
                {<ExtensionOutlinedIcon />}). Once completed, the information
                will unlock and become visible. You may also run into games that
                require you to listen to an audio file (indicated by{" "}
                {<HearingIcon />}) or call a phone number (indicated by{" "}
                {<PhoneInTalkOutlinedIcon />}) to complete them. The phone
                number will be provided within the game instructions.
              </Typography>

              <Typography variant="h6" align="left">
                Rankings and Prizes
              </Typography>
              <Typography variant="body1" paragraph align="left">
                At any point during the event, you’ll be able to see how you’re
                doing compared to the other teams you are competing against.
                Simply click “Rankings” in the menu to view how you stack up
                against others and see how many points you’ll need to get to
                move up. Points for open-ended questions (indicated by{" "}
                {<CachedIcon />}) aren’t awarded until after the event ends, so
                they won’t be displayed on the rankings page during the event.
              </Typography>

              <Typography variant="body1" align="left">
                Prizes will be announced prior to the event
              </Typography>
              {/* <Typography variant="body1" align="left">
                The following prizes will be awarded to the teams that finish in
                the top three:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" align="left">
                    First Place: 3x $20 Amazon Gift Cards
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" align="left">
                    Second Place: 3x $15 Amazon Gift Cards
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" align="left">
                    Third Place: 3x $10 Amazon Gift Cards
                  </Typography>
                </li>
              </ul> */}
              <Typography variant="h6" align="left">
                Hints
              </Typography>
              <Typography variant="body1" paragraph align="left">
                You will have the ability to request a hint for some of the more
                challenging puzzles. To do this, click over to the “Submit
                Answer” tab of the individual game and click “Request Hint”. If
                you request a hint, the maximum amount of points you will be
                able to gain from the game will be two-thirds of the total
                points available in that game.
              </Typography>

              <Typography variant="h6" align="left" display="inline">
                Strategy
              </Typography>
              <Typography variant="body1" paragraph align="left">
                You should remain on a video call with your teammates for the
                duration of the event. You’ll also want to grab a notebook and a
                pen to work through the puzzles. You can divide and conquer, or
                one person can share their screen and your entire team can work
                through one puzzle at a time. We strongly recommend having one
                designated person on your team who shares their screen and is in
                charge of entering the solutions.
              </Typography>

              <Typography variant="body1" paragraph align="left">
                Will you try to complete as many of the games as possible within
                the time limit, or will you focus on the ones with the higher
                point values? Neither strategy guarantees a win, so figure out
                the strengths of your teammates and play to them!
              </Typography>
              <Typography variant="h6" align="left">
                Getting Started
              </Typography>
              <Typography variant="body1" paragraph align="left">
                Once you or one of your teammates clicks “Start Event” on the
                Events page, the timer for your team will begin and you will
                have <strong>75</strong> minutes to gain as many points as
                possible. Your number of points and time remaining will be
                displayed in the menu at the top of every page. Do not click
                “Start Event” until all of your teammates are ready!
              </Typography>
              <Typography variant="h6" align="left">
                Final Standings
              </Typography>
              <Typography variant="body1" paragraph align="left">
                After the event ends for all teams, the points for open-ended
                questions will be tallied and the final results will be sent to
                the event host, who will then announce the winners.
              </Typography>
            </div>
          </Container>
        </MainLayout>
      </MiniDrawer>
    </div>
  );
}

export default EventsLandingPage;
