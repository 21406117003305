import React from "react";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

type HeartContainerProps = {
  hearts_allowed: number;
  hearts_used: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heart: {
      fontSize: 'xx-large',
      color: "secondary"
    },
  })
);


function HeartContainer({ hearts_allowed, hearts_used }: HeartContainerProps) {
  const classes = useStyles();

  const buildHeartContainer = () => {
    var hearts: React.ReactElement[] = [];
    var heartArray = [...Array(hearts_allowed)];
    heartArray.map((value: undefined, index: number) => {
      if (index + 1 <= hearts_allowed - hearts_used) {
        hearts.push(<FavoriteIcon color="secondary" className={classes.heart} />);
      } else {
        hearts.push(<FavoriteBorderIcon color="secondary" className={classes.heart} />);
      }
    });

    return hearts;
  };

  return <div className="heart_container">{buildHeartContainer()}</div>;
}

export default HeartContainer;
