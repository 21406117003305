export const gameData = [
  {
    id: 1,
    title: "Number Game",
    instructions: "Some description",
    answer: "2020",
    points: 3,
    gameType: 1
  },
  {
    id: 1,
    title: "Grid Game",
    instructions: "Some description",
    answer: "123456789",
    points: 6,
    gameType: 2
  },
  {
    id: 1,
    title: "DnD Game",
    instructions: "Some description",
    answer: "12345",
    points: 9,
    gameType: 3,
    extra: 5
  }
]
