import React, { useState, useEffect } from "react";

import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Countdown, { zeroPad } from "react-countdown";

import HeartContainer from "components/HeartsContainer/HeartsContainer";
import { IGameSettingsProps, IGameProgressProps } from "components/Games/IGame";
import { axiosInstance } from "api/axiosApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      border: "1px solid black",
      background:
        "repeating-linear-gradient(45deg,  rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px)",
      position: "absolute"
    },
    questionImage: {
      'max-width': '100%'
    }
  })
);

interface Props {
  id: number;
  button?: React.ReactNode;
  title?: string;
  question?: string;
  instructions?: string;
  questionImageUrl?: string;
  game?: React.ReactNode;
  requestHint: (puzzle_id: number) => void;
  // checkAnswer: (puzzle_id: number, answer: string) => void;
  numberOfAttempts?: number;
  settings?: IGameSettingsProps;
  progress?: IGameProgressProps;
  resetButton?: React.ReactNode;
  hasHint?: boolean;
}
const GameLayout = (props: Props) => {
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [isTimed, setIsTimed] = useState(false);
  const [hasTimerStarted, setHasTimerStarted] = useState(false);
  const [hasTimerEnded, setHasTimerEnded] = useState(false);
  const [endTime, setEndTime] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (props.numberOfAttempts) {
      // console.debug("Number of attempts changed to ", props.numberOfAttempts);
      setNumberOfAttempts(props.numberOfAttempts);

      if (
        props.settings &&
        props.settings.allowed_number_of_tries &&
        props.settings.allowed_number_of_tries <= props.numberOfAttempts
      ) {
        setIsActive(false);
      }
    }
  }, [props.numberOfAttempts]);

  useEffect(() => {
    if (props.settings && props.settings.is_timed) {
      setIsTimed(props.settings.is_timed);
      if (props.progress?.has_started) {
        console.debug("Has Started: ", props.progress.has_started);
        setHasTimerStarted(props.progress.has_started);
        setEndTime(props.progress.end_at.toString());
      }
    }
  }, [props.settings?.is_timed, props.progress]);

  const addSubmitButton = () => {
    console.debug("Submit Button: ", props.button);
    if (props.button && isActive) {
      return props.button;
    }
  };

  const countdownTimer = () => {
    // console.debug("Displaying Timer because we have the event progress");
    return (
      <Countdown
        date={endTime}
        key="countdown"
        onStart={time => {
          console.debug("Timer upon start: ", time);
          setIsActive(!time.completed);
          setHasTimerEnded(time.completed);
        }}
        onMount={calcTimeDelta => {
          setIsActive(!calcTimeDelta.completed);
          setHasTimerEnded(calcTimeDelta.completed);
        }}
        onComplete={() => {
          setIsActive(false);
          setHasTimerEnded(true);
        }}
        onTick={time => {}}
        renderer={({ hours, minutes, seconds, completed }) => {
          return (
            <span>
              {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
          );
        }}
      />
    );
  };

  const startPuzzleTimer = async () => {
    console.debug("Start timer props: ", props);
    if (props.progress && props.progress.id) {
      axiosInstance
        .post("puzzle/start", {
          puzzle_progress_id: props.progress.id
        })
        .then(result => {
          console.debug("Received Response for starting puzzle: ", result.data);
          setIsActive(result.data.active);
          setHasTimerStarted(result.data.active);
          setEndTime(result.data.end_time.toString());
        })
        .catch(err => {
          console.log("There was an error");
          console.log(err);
        });
    } else {
      console.debug("There is no progress available");
    }
  };

  const addStartButton = () => {
    if (isTimed && hasTimerStarted == false) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={(event: React.SyntheticEvent) => {
            event.preventDefault();
            {
              /* props.requestHint.call(undefined, props.id); */
            }
            console.debug("Start Timer");
            startPuzzleTimer();
          }}
          className="mr-1"
        >
          Start Now
        </Button>
      );
    }
  };

  // const submitButton = () => {
  //   if (props.requestHint && isActive) {
  //     return (
  //       <Button
  //         variant="contained"
  //         color="secondary"
  //         onClick={(event: React.SyntheticEvent) => {
  //           event.preventDefault();
  //           props.checkAnswer.call(undefined, props.id, );
  //         }}
  //         className="mr-1"
  //       >
  //         Request Hint
  //       </Button>
  //     );
  //   }
  // };

  const requestHintButton = () => {
    if (props.requestHint && isActive) {
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={(event: React.SyntheticEvent) => {
            event.preventDefault();
            props.requestHint.call(undefined, props.id);
          }}
          className="mr-1"
        >
          Request Hint
        </Button>
      );
    }
  };

  const disableGame = (event: React.SyntheticEvent) => {
    if (!isActive) {
      event.preventDefault();
    }
  };

  if (isTimed && hasTimerStarted == false) {
    return (
      <div className="game py-2 px-2">
        <div className="d-flex justify-content-center">{addStartButton()}</div>
      </div>
    );
  } else {
    return (
      <div className="game py-2 px-2">
        <Backdrop className={classes.backdrop} open={!isActive}></Backdrop>
        {console.debug("Settings: ", props.settings)}
        {props.settings && props.settings.can_change_answer
          ? <div><p>You can change this answer as many times as you like until the end of the event.
          The teams with the correct answer at the end will receive the points.</p></div>
          : ""}
        {props.settings && props.settings.can_change_answer
          ? props.progress?.last_answer
            ? "Last Answer: " + props.progress.last_answer
            : "You have not submitted an answer yet"
          : ""}
        {endTime ? (
          hasTimerEnded ? (
            "No time remaining"
          ) : (
            <div>{countdownTimer()}</div>
          )
        ) : (
          ""
        )}
        {props.title ? (
          <div className="d-flex justify-content-center">
            <h3>
              <strong>{props.title}</strong>
            </h3>
          </div>
        ) : (
          ""
        )}
        {props.settings && props.settings.allowed_number_of_tries && props.progress?.is_complete == false ? (
          <div className="d-flex justify-content-center">
            <HeartContainer
              hearts_allowed={props.settings.allowed_number_of_tries}
              hearts_used={numberOfAttempts}
            />
          </div>
        ) : (
          ""
        )}
        {props.settings && props.settings.allowed_number_of_tries && props.progress?.is_complete == false ? (
          <div className="d-flex justify-content-center">
            <h5>
              <strong>
                {numberOfAttempts < props.settings.allowed_number_of_tries
                  ? props.settings.allowed_number_of_tries -
                    numberOfAttempts +
                    " attempt(s) remaining"
                  : "No attempts remaining"}
              </strong>
            </h5>
          </div>
        ) : (
          ""
        )}
        {props.question || props.questionImageUrl ? (
          <div className="d-flex justify-content-center">
            {props.questionImageUrl ? (
              <div>
                <img src={props.questionImageUrl} className={classes.questionImage}/>
              </div>
            ) : (
              <h5>
                <strong>{props.question}</strong>
              </h5>
            )}
          </div>
        ) : (
          ""
        )}
        {props.instructions ? (
          <div className="d-flex justify-content-center">
            <h5>
              <strong>{props.instructions}</strong>
            </h5>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex justify-content-center">{props.game}</div>
        <div className="d-flex grid-game justify-content-center mt-1">
          {props.resetButton}
          {props.hasHint ? requestHintButton(): ""}
          {addSubmitButton()}
        </div>
      </div>
    );
  }
};
export default GameLayout;
