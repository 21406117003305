import React from "react";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import MainLayout from "components/Layouts/MainLayout";
import MiniDrawer from "components/Navigation/MiniDrawer";
import TemporaryDrawer from "components/Navigation/TemporaryDrawer";
import Events from "./Events";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center"
    }
  })
);

function EventsPage({ children }: { children: React.ReactNode }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TemporaryDrawer>
        {/* <MiniDrawer> */}
        <MainLayout>
          <Events />
        </MainLayout>
      {/* </MiniDrawer> */}
      </TemporaryDrawer>
    </div>
  );
}

export default EventsPage;
