import React, { useState } from "react";

import ReactPlayer from "react-player";
import { Row, Col } from "reactstrap";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ReactAudioPlayer from "react-audio-player";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

import { IGameProgressProps } from "components/Games/IGame";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: 500,
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      '& .MuiBox-root': {
        padding: 0
      }
    }
  },
  media: {
    maxHeight: 400,
    // height: '50%',
    objectFit: "scale-down"
    // objectFit: 'fill',
  },
  panel: {
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  }
}));

type Prop = {
  instructions: string;
  imageUrl: string;
  video?: { name: string; url: string };
  audio?: { upload_url: string };
  progress?: IGameProgressProps;
  children: React.ReactNode;
};

const TabLayout = (props: Prop) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label="Instructions" {...a11yProps(0)} classes={{root: classes.panel}}/>
        <Tab label="Submit Answer" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Card className={classes.root} onClick={() => setIsPlaying(true)}>
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="h5">
                <h5>
                <div
                  dangerouslySetInnerHTML={{ __html: props.instructions }}
                ></div>
              </h5>
              </Typography>
              {props.audio ? (
                <ReactAudioPlayer src={props.audio.upload_url} controls />
              ) : (
                ""
              )}
            </CardContent>
            {props.progress?.current_additional_image ?
              (
              <CardMedia
                className={classes.media}
                component="img"
                image={props.progress.current_additional_image.image.upload_url}
              />) : (<CardMedia
                className={classes.media}
                component="img"
                image={props.imageUrl}
              />)
            }
            {props.video ? (
              <ReactPlayer
                url={props.video.url}
                muted={true}
                onClick={() => {
                  console.debug("Player is being clicked on");
                }}
                playing={isPlaying}
                onEnded={() => {
                  console.debug("Video has ended");
                  {
                    /* setIsPlaying(false); */
                  }
                }}
                width="100%"
                height="100%"
              />
            ) : (
              ""
            )}
          <CardActions></CardActions>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Row>
          <Col className="mx-auto">{props.children}</Col>
        </Row>
      </TabPanel>
    </div>
  );
};

export default TabLayout;
