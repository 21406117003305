import React, { useState, useEffect } from "react";

import "./LetterSquare.css";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type Props = {
  id?: string;
  index: number;
  setLetter: (index: number, number: string) => void;
  limitedSelections?: {value:string}[];
};

const LetterSquare = (props: Props) => {
  const [letter, setLetter] = useState("A");
  const [letterAscii, setLetterAscii] = useState(65);
  const [useLimitedSelections, setUseLimitedSelections] = useState(false);
  const [limitedSelections, setLimitedSelections] = useState<
    { value: string }[]
  >([]);
  const [limitedSelectionsIndex, setLimitedSelectionsIndex] = useState(0);

  useEffect(() => {
    if (props.limitedSelections) {
      console.debug("Limited Selection: " + props.limitedSelections[0].value)
      setUseLimitedSelections(true);
      setLimitedSelections(props.limitedSelections);
      setLimitedSelectionsIndex(0);
    }
  }, props.limitedSelections);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.debug("onChange is being called - ", event.target.value);
    console.debug("Event: ", event);
    // if(letter === 9) {
    //   setLetter(0);
    //   props.setLetter(props.index, 0);
    // } else {
    //   setLetter(letter + 1);
    //   props.setLetter(props.index, letter + 1);
    // }

    props.setLetter(props.index, event.target.value);
  };

  const onClickUp = (event: React.MouseEvent<SVGSVGElement>) => {
    console.log("onClick is being called");
    console.debug("Limited Selections: ", limitedSelections);
    if (useLimitedSelections) {
      // Go back to zero
      if (limitedSelectionsIndex >= limitedSelections.length-1) {
        setLimitedSelectionsIndex(0);
        setLetter(limitedSelections[0].value);
        props.setLetter(props.index, limitedSelections[0].value);
      } else {
        setLetter(limitedSelections[limitedSelectionsIndex + 1].value);
        setLimitedSelectionsIndex(limitedSelectionsIndex + 1);
        props.setLetter(props.index, limitedSelections[limitedSelectionsIndex + 1].value);
      }
    } else {
      if (letterAscii === 90) {
        setLetterAscii(65);
        props.setLetter(props.index, String.fromCharCode(65));
        setLetter(String.fromCharCode(65));
      } else {
        setLetterAscii(letterAscii + 1);
        props.setLetter(props.index, String.fromCharCode(letterAscii + 1));
        setLetter(String.fromCharCode(letterAscii + 1));
      }
    }
  };

  const onClickDown = (event: React.MouseEvent<SVGSVGElement>) => {
    console.log("onClick is being called");
    if (useLimitedSelections) {
      // Go back to zero
      if (limitedSelectionsIndex <= 0) {
        setLimitedSelectionsIndex(limitedSelections.length-1);
        setLetter(limitedSelections[limitedSelections.length-1].value);
        props.setLetter(props.index, limitedSelections[limitedSelections.length-1].value);
      } else {
        setLetter(limitedSelections[limitedSelectionsIndex - 1].value);
        setLimitedSelectionsIndex(limitedSelectionsIndex - 1);
        props.setLetter(props.index, limitedSelections[limitedSelectionsIndex - 1].value);
      }
    } else {
      if (letterAscii === 65) {
        setLetterAscii(90);
        props.setLetter(props.index, String.fromCharCode(90));
        setLetter(String.fromCharCode(90));
      } else {
        setLetterAscii(letterAscii - 1);
        props.setLetter(props.index, String.fromCharCode(letterAscii - 1));
        setLetter(String.fromCharCode(letterAscii - 1));
      }
    }
  };

  const displaySquare = () => {
    return letter;
  };

  return (
    <div>
      <ExpandLessIcon onClick={onClickUp} fontSize="large" />
      <div
        className="letter-square noselect justify-align-center"
        id={props.id}
        key={props.id}
      >
        {/* <input className="letter-input" type="text" name="letter" maxLength={1} onChange={onChange} /> */}
        <div><span>{letter}</span></div>
      </div>
      <ExpandMoreIcon onClick={onClickDown} fontSize="large" />
    </div>
  );
};

export default LetterSquare;
