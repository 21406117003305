import React, { useState, useEffect } from "react";

import classNames from "classnames";

import { DataGrid, ColDef, ValueGetterParams } from "@material-ui/data-grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import { XGrid, useApiRef } from '@material-ui/x-grid';
import { interval } from "rxjs";

import { useLocalStorage, useMapState } from "components/Utils/Storage";
import { axiosInstance } from "api/axiosApi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  table: {
    minWidth: 300,
    // maxWidth: 400
  },
  up: {
    backgroundColor: theme.palette.success.main
  },
  down: {
    backgroundColor: theme.palette.error.main
  }
}));

interface RankingsProps {
  refresh? : number;
  showChange?: boolean;
}

export const Rankings = (props: RankingsProps) => {
  const classes = useStyles();
  const [rankings, setRankings] = useState([]);
  const [pastRankings, setPastRankings] = useState(new Map<string, number>())
  const [showChange, setShowChange] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [eventId, setEventId] = useLocalStorage(
    "current_event_id",
    0
  );

  const getRankings = () => {
    axiosInstance
      // .get("event/" + eventProgressId + "/puzzle/" + puzzle_id + "/hint")
      .get("event/" + eventId + "/rankings")
      .then(result => {
        console.debug("Received response from post: ", result);
        rankings.map(
          (row: { team_name: string; total_points: number }, i) => {
            setPastRankings(pastRankings.set(row.team_name, i));
          });
        setRankings(result.data);
        console.debug("Past Rankings: ", pastRankings);
      })
      .catch(err => {
        console.debug("There was an error");
        console.debug(err);
        return false;
      });
  };

  useEffect(() => {
    getRankings();
  }, [props.refresh]);

  useEffect(() => {
    if(props.showChange) {
      setShowChange(props.showChange);
    }

    getRankings();
  }, []);

  const columns: ColDef[] = [
    { field: "team_name", headerName: "Team Name", type: "number", width: 130 },
    { field: "total_points", headerName: "Points", type: "number", width: 130 }
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: ValueGetterParams) =>
    //     `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    // },
  ];

  // const showChangeColumns: ColDef[] = [
  //   { field: "team_name", headerName: "Team Name", type: "number", width: 130 },
  //   { field: "total_points", headerName: "Points", type: "number", width: 130 },
  //   { field: "change", headerName: "Change", type: "string", width: 130 }
  // ];

  const buildTable = () => {
    return (
        <Table className={classes.table} aria-label="Rankings">
          <TableHead>
            <TableRow>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="center">Team Name</TableCell>
              <TableCell align="center">Points</TableCell>
              {showChange ? <TableCell align="center">Change</TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rankings.map(
              (row: { team_name: string; total_points: number }, i) => {
                {/* console.debug("row: ", row); */}
                let pastRank = pastRankings.get(row.team_name);
                console.debug("past Ranking for ", row.team_name ,": ", pastRank);
                return (
                  <TableRow key={i} className={classNames({
                    [`${classes.up}`]: pastRank && pastRank > i
                  }, {
                    [`${classes.down}`]: pastRank && pastRank < i
                  })}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">{row.team_name}</TableCell>
                    <TableCell align="center">{row.total_points}</TableCell>
                    {showChange ? <TableCell align="center">{pastRank ? pastRank-i : 0}</TableCell> : <></>}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
    );
  };


  if(rankings.length == 0) {
    return (<div>No rankings available</div>)
  } else {
    return buildTable();
  }
};
