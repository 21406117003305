import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link as UiLink } from '@material-ui/core';

import Typed from "react-typed";

import { Switch, Route, Link } from "react-router-dom";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        VirtualTeamBuilding.io
      </Link>{" - A "}
      <UiLink href="http://www.nodus805.com" underline="always" color="inherit">
        {'Nodus 805'}
      </UiLink>
      {" Virtual Team Building Experience "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  event: {
    color: theme.palette.secondary.main
  }
}));

export default function Home() {
  const classes = useStyles();
  const displayHome = () => {
    return (
      <div>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h2">
              Virtual Team Building for your
            </Typography>
            <Typography component="h1" variant="h2" className={classes.event}>
              <Typed
                strings={[
                  "offsite",
                  "holiday party",
                  "happy hour",
                  "event"
                ]}
                typeSpeed={40}
                backSpeed={80}
                loop
              ></Typed>
            </Typography>
            <div className="mt-4">
              <Typography component="h1" variant="h5">
                Schedule your Virtual Team Building Event today!
              </Typography>
            </div>
            <div className="mt-4">
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="a"
                href="https://events.nodus805.com/contactus"
                className="mr-2"
              >
                Schedule
              </Button>

                <Button
                  size="large"
                  variant="outlined"
                  color="primary"
                  component="a"
                  href="/login"
                >
                  Sign In
                </Button>
            </div>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    );
  };

  return <div>{displayHome()}</div>;
}
