import React, { Component } from 'react';
import BoardContainer from "components/MainContainer/BoardContainer";
import {
  useHistory
} from 'react-router-dom'

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import RestoreIcon from '@material-ui/icons/Restore';

const routes = [
  {
    path: "/games",
    name: "Games",
    component: BoardContainer
  }, {
    path: "/",
    name: "Index",
    component: Index
  }
];

function Index() {
  return (<h1>Index</h1>);
}

const useStyles = makeStyles({
  navigation: {
    width: 500,
    border: '3px solid',
    marginRight: 'auto',
    marginLeft: 'auto',
  }
});

export default function DisplayNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  let history = useHistory();

  return (<BottomNavigation value={value} onChange={(event, newValue) => {
    setValue(newValue);
    console.log("New Value: ", newValue);
    history.push(newValue);
  }} showLabels className={classes.navigation}>
    {routes.map((route, i) => (<BottomNavigationAction label={route.name} value={route.path} icon={<RestoreIcon />} />))}
  </BottomNavigation>);
}
