import React, {useState} from "react"

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import MainLayout from "components/Layouts/MainLayout"
import MiniDrawer from "components/Navigation/MiniDrawer"
import TemporaryDrawer from "components/Navigation/TemporaryDrawer"
import {Rankings} from "components/Rankings/Rankings"
import ProgressTimer from "components/ProgressTimer/ProgressTimer"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    marginAutoItem: {
      margin: "auto"
    },
    marginAutoContainer: {
      display: "flex",
    }
  })
);

function RankingsPage() {
  const classes = useStyles();
  const [refreshNumber, setRefreshNumber] = useState(0);

  const onTimerComplete = () => {
    setRefreshNumber(refreshNumber + 1);
  }

  return (
    <div className={classes.root}>
      <TemporaryDrawer>
      {/* <MiniDrawer> */}
          <MainLayout>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ProgressTimer label="Something" seconds={60} onComplete={onTimerComplete}/>
              <Rankings refresh={refreshNumber}/>
            </Grid>
          </Grid>
        </MainLayout>
      {/* </MiniDrawer> */}
      </TemporaryDrawer>
    </div>
  );
}

export default RankingsPage
