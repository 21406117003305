import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

type Props = {
  visible: boolean;
  waitingForMessage?: boolean;
  buttonLabel?: string;
  className?: string;
  title?: string;
  cancelText?: string;
  acceptText?: string;
  handleClose: () => void;
  handleAccept: () => void;
  children: React.ReactNode;
};

export default function AlertDialog(props: Props) {
  const [open, setOpen] = React.useState(props.visible);

  useEffect(() => {
    setOpen(props.visible);
  }, [props.visible]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.acceptText ? (
            <Button onClick={props.handleAccept} color="primary" autoFocus>
              {props.acceptText}
            </Button>
          ) : (
            ""
          )}
          {props.cancelText ? (
            <Button onClick={props.handleClose} color="primary">
              {props.cancelText}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
