import React, { Component, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import { Link as UiLink } from '@material-ui/core';

import { Redirect } from "react-router-dom";
import { axiosInstance, axiosLoginInstance } from "api/axiosApi";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <UiLink color="inherit" href="">
        VirtualTeamBuilding.io
      </UiLink>{" - A "}
      <UiLink href="http://www.nodus805.com" underline="always" color="inherit">
        {'Nodus 805'}
      </UiLink>
      {" Virtual Team Building Experience "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export const SignIn = () => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == "username") {
      setUsername(event.target.value);
    }

    if (event.target.name == "password") {
      setPassword(event.target.value);
    }
  };

  const getTeamName = () => {
    axiosInstance
      .get("user/" + username)
      .then(response => {
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + response.data.access;
        localStorage.setItem("username", username);
        localStorage.setItem("team_name", response.data.team_name);
        console.debug("team_name: ", response.data.team_name);
        return response.data;
      })
      .catch(err => {
        console.log("There was an error");
        console.log(err);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    axiosLoginInstance
      .post("token/", {
        username: username,
        password: password
      })
      .then(response => {
        setMessage("Success");
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + response.data.access;
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        getTeamName();
        console.debug("access_token: ", response.data.access);
        setLoggedIn(true);
        return response.data;
      })
      .catch((err:any):any => {
        console.debug("Error: ", err);
        console.debug("Error Response: ", err.response);
        if (err.response.status == 400 || err.response.status == 401) {
          if (
            err.response.data.password &&
            err.response.data.password.length > 0
          ) {
            console.debug("Message: ", err.response.data.password[0]);
            setMessage(err.response.data.password[0]);
          } else {
            console.debug("Message: ", err.response.data.detail);
            setMessage(err.response.data.detail);
          }
        }
      });
  };

  if (loggedIn) {
    return <Redirect to="/events" />;
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Alert severity="info">You should have received your login credentials in an email; if you did not, please contact your event host.</Alert>
          <Typography component="h1" variant="h5">
            {message}
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={username}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
};
